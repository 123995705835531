import React, { useState, useEffect } from "react";
import DepositNotice from "../../notice/mobile/DepositNotice";
import { Link, useNavigate } from "react-router-dom";
import Toolbar from "../../navigation/mobile/Toolbar";
import UniversalBack from "../../common/mobile/UniversalBack";
import { Modal } from "react-bootstrap";
import Splash from "../../../splash/Splash";

function MDeposit({ items, promotions }) {
  const [activeItem, setActiveItem] = useState(null);
  const [activeAmount, setActiveAmount] = useState("");
  const [selectedPromotion, setSelectedPromotion] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [paymentSystems, setPaymentSystems] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const token = localStorage.getItem("token");
  const amounts = ["2000", "3000", "5000", "10000", "15000", "20000", "25000", "1000", "500", "100"];
  const [selectedOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();

  // ✅ Auto-select the first payment method
  useEffect(() => {
    if (items.length > 0) {
      setPaymentMethod(items[0]);
    }
  }, [items]);

  // ✅ Auto-select the first promotion
  useEffect(() => {
    if (promotions.length > 0) {
      setSelectedPromotion(promotions[0]);
    }
  }, [promotions]);

  // ✅ Fetch deposit channels when payment method changes
  useEffect(() => {
    if (paymentMethod) {
      const fetchPaymentMethodDetails = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/showpayment-menthod?name=${paymentMethod.name}`, {
            headers: { Authorization: `Bearer ${token}`, 'Origin': window.location.origin },
          });
          const data = await response.json();

          if (data?.success && data.data.length > 0) {
            setPaymentSystems(data.data);
            setSelectedOption(data.data[0]?.id || null); // ✅ Auto-select the first deposit channel
          }
        } catch (error) {
          console.error("Error fetching payment method details:", error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchPaymentMethodDetails();
    }
  }, [paymentMethod, token]);

  const handleItemClick = (name) => {
    const selectedMethod = items.find((item) => item.name === name);
    if (selectedMethod) {
      setPaymentMethod(selectedMethod);
      setSelectedOption(null);
    }
  };

  const handleSelection = (id) => {
    setSelectedOption(id);
  };

  const handleAmountClick = (amount) => {
    setActiveAmount(amount);
  };

  const handlePromotionChange = (event) => {
    const selectedId = parseInt(event.target.value);
    const selectedPromo = promotions.find((promo) => promo.id === selectedId);
    setSelectedPromotion(selectedPromo || null);
  };

  const handleSubmit = () => {
    if (!activeAmount) {
      setModalMessage("Please fill in your amount.");
      setShowModal(true);
      return;
    }

    if (!paymentMethod) {
      setModalMessage("Please select a valid payment method.");
      setShowModal(true);
      return;
    }

    const selectedPaymentSystem = paymentSystems.find(item => item.id === selectedOption);
    if (!selectedPaymentSystem) {
      setModalMessage("Please select a Deposit channel.");
      setShowModal(true);
      return;
    }

    try {
      navigate("/make-payment", {
        state: {
          amount: activeAmount,
          payment_icon: selectedPaymentSystem.icon,
          payment_type: selectedPaymentSystem.account_type,
          paymentMethodid: paymentMethod.id,
          paymentMethodName: paymentMethod.name,
          promotion: selectedPromotion?.id || null,
        },
      });
    } catch (error) {
      setModalMessage("An error occurred. Please try again.");
      setShowModal(true);
    } finally {
      setSubmitLoading(false);
    }
  };

  if (isLoading) {
    return (
     <Splash/>
    );
  }

  return (
    <div className="main-router-wrapper">
      <header id="header" className="login player">
        <div className="header-left-btn-group">
          <UniversalBack />
        </div>
        <div className="header-title">Funds</div>
        <div
          className="logo"
          tabIndex={0}
          style={{ backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/logo.png")' }}
        />
      </header>

      <div className="wrap">
        <div className="content fixed-tab player-content">
          <div className="tab-btn-section tab-btn-wrap">
            <div className="tab-btn tab-btn-bar">
              <div className="line" style={{ width: "50%" }} />
              <div className="btn active">
                <Link className="text-white" to="/deposit">
                  <div className="text">Deposit</div>
                </Link>
              </div>
              <div className="btn">
                <Link className="text-white" to="/withdrawal">
                  <div className="text">Withdrawal</div>
                </Link>
              </div>
            </div>
          </div>

          <div className="tab-content disable">
            <div className="inner-wrap">
              <div className="inner-box deposit-wallet">
                <div className="player-deposit-wrap">
                  <div className="player-deposit-step1">
                    {/* Promotion Selector */}
                    <div className="option-group select-bar">
                      <label>
                        <span
                          className="item-icon"
                          style={{
                            backgroundImage:
                              'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-selectpromotion.svg")',
                          }}
                        />
                        <div>Select Promotion</div>
                      </label>
                      <div className="option-wrap">
                        <select
                          onChange={handlePromotionChange}
                          defaultValue={selectedPromotion?.id || ""}
                        >
                          {promotions.map((promotion) => (
                            <option key={promotion.id} value={promotion.id}>
                              {promotion.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <DepositNotice />

                    {/* Payment Method */}
                    <div className="menu-box">
                      <div className="title">
                        <h2>Payment Method</h2>
                      </div>
                      <div className="select-group checkbox-style">
                        <ul className="col3">
                          {items.map(
                            (item) =>
                              item.status === "active" && (
                                <li
                                  key={item.id}
                                  onClick={() => handleItemClick(item.name)}
                                  className="ng-star-inserted"
                                >
                                  <input
                                    type="radio"
                                    name="paymentMethod"
                                    id={`paymentMethod_${item.id}`}
                                    checked={paymentMethod?.name === item.name}
                                  />
                                  <label htmlFor={`paymentMethod_${item.id}`}>
                                    <div className="bank ng-star-inserted">
                                      <img
                                        alt={item.name}
                                        src={`${process.env.REACT_APP_API_URL}/storage/${item.icon}`}
                                        loading="lazy"
                                      />
                                    </div>
                                    <span>{item.name}</span>
                                    <div className="tag-rebate-money"><p ><span>+</span>{selectedPromotion?.deposit_bouns ? Math.round(selectedPromotion.deposit_bouns) : 0}
                                    <span>%</span></p></div>
                                  </label>
                                </li>
                              )
                          )}
                        </ul>
                      </div>
                    </div>
                    <div  className="deposit-normal ng-tns-c324864554-6 ng-star-inserted">
                        <div  className="ng-tns-c324864554-6 ng-trigger ng-trigger-staggerFadeAnimation ng-star-inserted" style={{}}>
                          <div  className="menu-box ng-tns-c324864554-6 ng-star-inserted" style={{}}>
                            <div  className="title ng-tns-c324864554-6">
                              <h2  className="ng-tns-c324864554-6"><span  className="ng-tns-c324864554-6">Deposit Channel</span></h2>
                            </div>
                            <div  className="select-group checkbox-style checkbox-height-set ng-tns-c324864554-6">
                            <ul className="col4 ng-tns-c324864554-6">
                            {paymentSystems.map(paymentSystem => (
                              <React.Fragment key={paymentSystem.id}>
                                {/* Show CashOut if account_type is Agent */}
                                {paymentSystem.account_type === "Agent" && (
                                  <li className="ng-star-inserted">
                                    <input  type="radio"  onChange={() => {
                                        handleSelection(paymentSystem.id);
                                        setActiveItem(paymentSystem.id);   
                                      }}
                                    name="depositSetting" id={`depositSetting_${paymentSystem.id}`}
                                    checked={selectedOption === paymentSystem.id} />
                                    <label htmlFor={`depositSetting_${paymentSystem.id}`}>
                                      <span>CashOut</span>
                                      <div className="tag-recommond ng-star-inserted">
                                      <span className="item-icon" style={{ maskImage: `url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-recommond.svg?v=1735038087741")` }} />

                                      </div>
                                      <span className="item-icon" style={{ maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/player/select-check.svg?v=1735038087741")' }} />
                                    </label>
                                  </li>
                                )}
                                
                                {/* Show Send Money if account_type is Personal */}
                                {paymentSystem.account_type === "Personal" && (
                                  <li className="ng-star-inserted">
                                    <input type="radio" name="depositSetting" onChange={() => handleSelection(paymentSystem.id)}  id={`depositSetting_${paymentSystem.id}`} />
                                    <label htmlFor={`depositSetting_${paymentSystem.id}`}>
                                      <span>Send Money</span>
                                      <span className="item-icon" style={{ maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/player/select-check.svg?v=1735038087741")' }} />
                                    </label>
                                  </li>
                                )}
                              </React.Fragment>
                            ))}
                          </ul>
                            </div>
                          </div>
                        </div>
                      </div>

                    {/* Amount Selection */}
                    <div className="menu-box active">
                      <div className="title">
                        <h2>
                          Amount <i>৳ 100.00 - ৳ 25,000.00</i>
                        </h2>
                      </div>
                      <div className="select-group style-add-amount">
                        <ul className="col4">
                          {amounts.map((amount) => (
                            <li
                              key={amount}
                              onClick={() => handleAmountClick(amount)}
                            >
                              <input
                                type="radio"
                                name="depositAmount"
                                id={`depositAmount_${amount}`}
                              />
                              <label htmlFor={`depositAmount_${amount}`}>
                                <span>{amount}</span>
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="input-group money">
                        <label htmlFor="amount">৳</label>
                        <div className="inputbox">
                        <input
                          type="text"
                          name="amount"
                          className="input text-right"
                          value={activeAmount}
                          onChange={(e) => setActiveAmount(e.target.value)} 
                          onClick={(e) => e.stopPropagation()} 
                        />
                        </div>
                      </div> 
                    </div>

                    {/* Submit Button */}
                    <div
                      style={{ textAlign: "center" }}
                      className="member-content "
                    >
                      <button
                      onClick={handleSubmit}
                        className="button">
                       Next
                      </button>
                    </div>
                   <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>Error</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>{modalMessage}</p>
                            </Modal.Body>
                        </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toolbar />
    </div>
  );
}

export default MDeposit;
