import React, { useState, useEffect } from "react";

function NewsPopUp() {
  const [showPopup, setShowPopup] = useState(true);

  useEffect(() => {
    const popupStatus = localStorage.getItem("popup");
    if (popupStatus === "0") {
      setShowPopup(false);
    }
  }, []);

  const handleClose = () => {
    localStorage.setItem('popup', '0');
    setShowPopup(false);
  };

  if (!showPopup) return null;

  return (
    <div className="cdk-overlay-container">
      <div className="cdk-overlay-backdrop dialog-backdrop cdk-overlay-backdrop-showing" />
      <div className="cdk-global-overlay-wrapper" dir="ltr" style={{ justifyContent: 'center', alignItems: 'center' }}>
        <div id="cdk-overlay-2" className="cdk-overlay-pane dialog-panel" style={{ position: 'static' }}>
          <div className="popup" id="dialog-2">
            <div className="popup__header"></div>
            <div className="popup__content">
              <div className="pop-wrap promotion-pop new ng-trigger ng-trigger-popWrapTriggerAni ani show ">
                <a className="btn-close" onClick={handleClose}>
                  <span className="item-icon" style={{ backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-cross-type01.svg?v=1743412106882")' }} />
                </a>

                <div className="pop-title">
                  <h3>【Platform Maintenance Notification】</h3>
                </div>
                <div className="pop-inner content-style">
                  <div>
                    <p>
                      Dear customer,<br />
                      Please be informed that our【CQ9】platform will be under maintenance according to Bangladesh time.
                    </p>
                    <p>【CQ9】 (2025/03/28) Now - Until (2025/04/16)</p>
                    <p>
                      Remark: During maintenance time, the games won't be able to access.<br />
                      Thank you for your cooperation and patience. We sincerely apologize for your temporary inconvenience.
                    </p>
                  </div>
                </div>
              </div>

              <div className="pop-bg" style={{ display: 'block' }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsPopUp;
