import React, { useState } from 'react';

function Splash() {
  const origin = window.location.hostname
    .replace(/^www\./, '')
    .replace(/\.(com|live|net|bet|buzz)$/i, '');

  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  if (origin === 'jitbuzz') {
    return (
      <div className="splash-screen">
      <div className="loader-box ng-star-inserted">
        <div className="loader-jb">
          <div className="loader-jb">
            <div className="all-loader-frame">
              <div className="loader-1" />
              <div className="loader-bg" />
              <div className="loader-2" />
              <div className="loader-bg2" />
            </div>
            <div className="jb-logo-bg">
              <div
                className="jb-logo"
                style={{
                  backgroundImage:
                    'url("https://img.j189eb.com/jb/h5/assets/images/loader/jb_logo.png?v=1743412106882")',
                }}
              />
              <div
                className="jb-logo-man"
                style={{
                  backgroundImage:
                    'url("https://img.j189eb.com/jb/h5/assets/images/loader/jblogo-man.png?v=1743412106882")',
                }}
              />
            </div>
            <div
              className="jb-logo-speedline"
              style={{
                backgroundImage:
                  'url("https://img.j189eb.com/jb/h5/assets/images/loader/jb-loader-03.png?v=1743412106882")',
              }}
            />
            <div
              className="jb-logo-ball"
              style={{
                backgroundImage:
                  'url("https://img.j189eb.com/jb/h5/assets/images/loader/jb_logo-ball.png?v=1743412106882")',
              }}
            />
          </div>
        </div>
      </div>
      </div>
    );
  }

  return (
    <div className="splash-screen">
      {imageError ? (
        <div className="three-dots-loading">
          <span></span>
          <span></span>
          <span></span>
        </div>
      ) : (
        <img
          src={`./splash/${origin}.gif`}
          onError={handleImageError}
          alt="Loading..."
        />
      )}
    </div>
  );
}

export default Splash;
