import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import WithdrawNotice from "../../notice/mobile/WithdrawNotice";
import Toolbar from "../../navigation/mobile/Toolbar";
import UniversalBack from "../../common/mobile/UniversalBack";
import { Modal } from "react-bootstrap"; 

function MWithdrawal({items, userData}) {
  const [activeItem, setActiveItem] = useState(null); 
  const [activeAmount, setActiveAmount] = useState(""); 
  const [icon, setIcons] = useState(''); 
  const [selectedAccount, setSelectedAccount] = useState(userData?.phone || "");
  const [submitLoading, setSubmitLoading] = useState(false); 
  const [showModal, setShowModal] = useState(false); 
  const [modalMessage, setModalMessage] = useState(""); 
  const [getOtherContracts, setOtherContracts] = useState([]);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const remoteId = localStorage.getItem('remote_id');
  const amounts = ["2000", "3000", "5000", "10000", "15000", "20000", "25000", "1000", "500"];
 
  const handleItemClick = (id, icon) => {
    setActiveItem(id); 
    setIcons(icon);
  };
  // Fetch other contracts
  useEffect(() => {
    fetchOtherContracts();
  }, []);

  // Set default selected account when data is available
  

  const fetchOtherContracts = async () => {
    if (token) {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/get/other/phone`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                // Filter contracts where isverify is 1
                const verifiedContracts = (data.data || []).filter(contract => contract.isverify === 1);
                setOtherContracts(verifiedContracts);
            } else {
                console.error("Failed to fetch contracts");
            }
        } catch (error) {
            console.error("Error fetching contracts:", error);
        }
    }
};

   
    
  const handleSelection = (value) => {
    setSelectedAccount(value);
    console.log(value);
  };

  const handleAmountClick = (amount) => {
    setActiveAmount(amount); 
  };
  const checkUserEligibility = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/userwithdrawcheck?remote_id=${remoteId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
  
      const data = await response.json();
      console.log('API response data:', data);
  
      if (!response.ok) {
        if (response.status === 400) {
          setModalMessage(data.error || 'Please complete your turnover and try again.');
          setShowModal(true);
          return false;
        } else {
          setModalMessage(data.error || 'An error occurred.');
          setShowModal(true);
          return false;
        }
      }
  
      if (data.success) {
        // User is eligible for withdrawal
        return true;
      } else {
        // Fallback for other cases
        setModalMessage('An unknown error occurred.');
        setShowModal(true);
        return false;
      }
    } catch (error) {
      return false;
    }
  };
  

  const handleSubmit = async () => {
    
    if (!activeAmount) {
      setModalMessage("Amount is required.");
      setShowModal(true);
      return;
    }
  
    if (!activeItem) {
      setModalMessage("Please select your payment method.");
      setShowModal(true);
      return;
    }
  
    // Check user eligibility
    const eligible = await checkUserEligibility();
    if (!eligible) {
    
      return;
    }
  
    setSubmitLoading(true);
  
    const remoteId = localStorage.getItem("remote_id");
  
    const requestBody = {
      remote_id: remoteId,
      account_number: selectedAccount,
      amount: activeAmount,
      payment_id: activeItem,
    };
  
    console.log(requestBody);
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/submit-withdraw`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        setModalMessage(errorData.error);
        setShowModal(true);
        return;
      }
  
      const data = await response.json();
  
      if (data.success) {
        setModalMessage("Withdrawal successful!");
        setShowModal(true);
        navigate("/payment-request", {
          state: {
            amount: activeAmount,
            payment_icon: icon,
          },
        });
      } else {
        setModalMessage(data.message);
        setShowModal(true);
      }
    } catch (error) {
      setModalMessage("An error occurred. Please try again.");
      setShowModal(true);
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <div className="main-router-wrapper">
      <header id="header" className="login player">
        <div className="header-left-btn-group">
          <UniversalBack />
        </div>
        <div className="header-title">Funds</div>
        <div
          className="logo"
          tabIndex={0}
          style={{ backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/logo.png")' }}
        />
      </header>

      <div className="wrap">
        <div className="content fixed-tab player-content">
          <div className="tab-btn-section tab-btn-wrap">
            <div className="tab-btn tab-btn-bar">
              <div className="line" style={{ width: "calc(50%)", transform: "translate(100%, 0px)" }} />
              <div className="btn">
                <Link className="text-white" to="/deposit">
                  <div className="text">Deposit</div>
                </Link>
              </div>
              <div className="btn active">
                <Link className="text-white" to="/withdrawal">
                  <div className="text">Withdrawal</div>
                </Link>
              </div>
            </div>
          </div>

          <div className="tab-content disable">
            <div className="inner-wrap">
              <div className="inner-box deposit-wallet">
              <div className="player-top player-withdraw ">
              <div className="main-wallet-info ">
                <div className="operating-wallet " />
                <div className="renew-main-wallet ">
                  <span className="">Main Wallet</span>
                  <div className="icon-refresh" style={{maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-refresh-type01.svg?v=1737451131402")'}} />
                </div>
                <h4 className=""><i id="locale-util-dicrective-2" className="" style={{display: 'initial'}}>৳ {userData?.balance || 0}</i></h4>
              </div>
              <span className="item-bg " style={{maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/player/bg-header-bottom.svg?v=1737451131402")'}} />
            </div>

                <div className="player-deposit-wrap">
                  <div className="player-deposit-step1">
                    <WithdrawNotice />
                    {/* Payment Method */}
                    <div className="menu-box">
                      <div className="title">
                        <h2>Payment Method</h2>
                      </div>
                      <div className="select-group checkbox-style">
                        <ul className="col3">
                          {items.map(
                            (item) =>
                              item.status === "active" && (
                                <li
                                  key={item.id}
                                  onClick={() => handleItemClick(item.id, item.icon)}

                                  className="ng-star-inserted"
                                >
                                  <input
                                    type="radio"
                                    name="paymentMethod"
                                    id={`paymentMethod_${item.id}`}
                                  />
                                  <label htmlFor={`paymentMethod_${item.id}`}>
                                    <div className="bank ng-star-inserted">
                                      <img
                                        alt={item.name}
                                        src={`${process.env.REACT_APP_API_URL}/storage/${item.icon}`}
                                        loading="lazy"
                                      />
                                    </div>
                                    <span>{item.name}</span>
                                  </label>
                                </li>
                              )
                          )}
                        </ul>
                      </div>
                    </div>

                    {/* Amount Selection */}
                    <div className="menu-box active">
                      <div className="title">
                        <h2>
                          Amount <i>৳ 500.00 - ৳ 25,000.00</i>
                        </h2>
                      </div>
                      <div className="select-group style-add-amount">
                        <ul className="col4">
                          {amounts.map((amount) => (
                            <li
                              key={amount}
                              onClick={() => handleAmountClick(amount)}
                            >
                              <input
                                type="radio"
                                name="withdrawAmount"
                                id={`withdrawAmount_${amount}`}
                              />
                              <label htmlFor={`withdrawAmount_${amount}`}>
                                <span>{amount}</span>
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="input-group money">
                        <label htmlFor="amount">৳</label>
                        <div className="input-wrap">
                         <input
                          type="text"
                          name="amount"
                          value={activeAmount}
                          placeholder="0.00"
                          onChange={(e) => setActiveAmount(e.target.value)}
                        />
                        </div>
                      </div>

                      {/* Account Number */}
                      <div  className="ng-tns-c646668852-11 ng-trigger ng-trigger-staggerFadeAnimation ng-star-inserted" style={{}}>
                    <div  className="menu-box withdraw-menu-phone ng-tns-c646668852-11">
                      <div  className="title ng-tns-c646668852-11">
                        <h2  className="ng-tns-c646668852-11">
                          <span  className="ng-tns-c646668852-11">Please select phone number</span></h2>
                      </div>
                      <div  className="select-group style-bank ng-tns-c646668852-11">
                      <ul className="ng-tns-c646668852-11">
                        {/* Default User Phone */}
                        <li  onClick={() => handleSelection(userData?.phone)} className={`ng-tns-c646668852-11 ng-star-inserted ${selectedAccount === userData?.phone ? "active" : ""}`}>
                            <input
                                type="radio"
                                value={userData?.phone}
                                checked={selectedAccount === userData?.phone}
                               
                                className={`ng-tns-c646668852-11 ${selectedAccount === userData?.phone ? "active" : ""}`}

                                name="accountPhone"
                            />
                            <label className="ng-tns-c646668852-11">
                                <div
                                    className="item-bg ng-tns-c646668852-11"
                                    style={{ backgroundImage: 'url("https://img.m167cw.com/mcw/h5/assets/images/player/bg-bankcard.png?v=1742288958382")' }}
                                />
                                <div className="select-card ng-tns-c646668852-11">
                                    <div className="select-card-inner ng-tns-c646668852-11">
                                        <div className="card-number ng-tns-c646668852-11">{userData?.phone || ""}</div>
                                    </div>
                                </div>
                                <span
                                    className="item-icon ng-tns-c646668852-11"
                                    style={{ backgroundImage: 'url("https://img.m167cw.com/mcw/h5/assets/images/icon-set/icon-check-type01.svg?v=1742288958382")' }}
                                />
                            </label>
                        </li>

                        {/* Other Contracts */}
                        {getOtherContracts.length > 0 &&
                            getOtherContracts.map((contract, index) => (
                                <li
                                   onClick={() => handleSelection(contract.contract_value)}
                                    key={index}
                                    className={`ng-tns-c646668852-11 ng-star-inserted ${selectedAccount === contract.contract_value ? "active" : ""}`}
                                >
                                    <input
                                        type="radio"
                                        value={contract.contract_value}
                                        checked={selectedAccount === contract.contract_value}
                                       
                                        className={`ng-tns-c646668852-11 ${selectedAccount === contract.contract_value ? "active" : ""}`}
                                        name="accountPhone"
                                    />
                                    <label className="ng-tns-c646668852-11">
                                        <div
                                            className="item-bg ng-tns-c646668852-11"
                                            style={{ backgroundImage: 'url("https://img.m167cw.com/mcw/h5/assets/images/player/bg-bankcard.png?v=1742288958382")' }}
                                        />
                                        <div className="select-card ng-tns-c646668852-11">
                                            <div className="select-card-inner ng-tns-c646668852-11">
                                                <div className="card-number ng-tns-c646668852-11">{contract.contract_value || ""}</div>
                                            </div>
                                        </div>
                                        <span
                                            className="item-icon ng-tns-c646668852-11"
                                            style={{ backgroundImage: 'url("https://img.m167cw.com/mcw/h5/assets/images/icon-set/icon-check-type01.svg?v=1742288958382")' }}
                                        />
                                    </label>
                                </li>
                            ))}
                    </ul>
                      </div>
                    </div>
                  </div>


                      <div
                        className="tips-info note ng-star-inserted"
                        style={{}}
                      >
                        <h5 className="">
                          <i
                            className="tips-icon"
                            style={{
                              maskImage:
                                'url("/assets/images/icon-set/icon-tips-type02.svg")',
                            }}
                          />
                          <span className="">
                            Reminder : <br/> 1. Please double check the recipient's
                            account details before proceeding. <br/> 2. DO NOT share
                            your account with anyone to avoid losing funds or
                            money. <br/> 3. Please make sure your bank account holder
                            name and our registered name match to prevent
                            withdrawal rejection.
                          </span>
                        </h5>
                      </div>
                    </div>

                    {/* Submit Button */}
                    <div style={{textAlign: 'center'}} className="member-content">
                      
                        <button className="button" onClick={handleSubmit} disabled={submitLoading}>
                          {submitLoading ? "Submitting..." : "Submit"}
                        </button>
                      
                    </div>

                    {/* Modal for Messages */}
                    <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>Error</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>{modalMessage}</p>
                            </Modal.Body>
                        </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toolbar />
    </div>
  );
}

export default MWithdrawal;
