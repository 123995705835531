import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const ScrollBanner = ({slidersFavourite}) => {
  const [isLoggedIn] = useState(!!localStorage.getItem('token'));
  
  const exchangeToken = localStorage.getItem('exchangeToken');

  // Fetch slidersFavourite data
  

  // Handle navigation using window.location.href
  const handleNavigation = (link) => {
    if (isLoggedIn) {
      // If the user is logged in, navigate to the provided link with the exchangeToken
      if (link) {
        window.location.href = `https://tech.betskyexch.com/m/#/token/${exchangeToken}`;
      }
    } else {
      // If the user is not logged in, redirect to the login page
      window.location.href = '/login';
    }
  };

  return (
    <div className="recommend scroll-banner">
      <div className="recommend-title">
        <h2>Favourites</h2>
      </div>
      <div className="recommend-bg">
        <div className="recommend-main">
          {slidersFavourite.length > 0 ? (
            slidersFavourite.map((slider, index) => (
              <div className="recommend-card" key={index} onClick={() => handleNavigation(slider.link)}>
               <Link>
               <img
                  alt={`slider_image_${index}`}
                  src={`${process.env.REACT_APP_API_URL}/storage/${slider.thubmnail}`}
                  loading="lazy"
                  style={{ cursor: 'pointer' }} // Change the cursor to pointer for better UX
                />
               </Link>
              </div>
            ))
          ) : (
            <p></p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ScrollBanner;
