import React, { useState, useEffect } from 'react';
import Toolbar from "../../navigation/mobile/Toolbar";
import UniversalBack from "../../common/mobile/UniversalBack";

function TurnoverRecord({turnovers}) {
    const [activeTab, setActiveTab] = useState("active");
    
    
    const isLoggedIn = !!localStorage.getItem('token');


    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        return new Date(dateString).toLocaleString('en-US', options);
    };

    const activeTurnovers = turnovers.filter(turnover => turnover.status === 'active');
    const completedTurnovers = turnovers.filter(turnover => turnover.status === 'complete');

    return (
        <div className="main-router-wrapper">
            <header id="header" className="login player">
                <div className="header-left-btn-group">
                    <UniversalBack/>
                </div>
                <div className="header-title">Turnover</div>
                <div className="logo" tabIndex={0} style={{ backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/logo.png?v=1729676340443")' }} />
            </header>

            <div className="wrap">
                <div className="content fixed-tab player-content">
                    <div className="tab-btn-section">
                        <div className="tab-btn tab-btn-page">
                            <div className="line" style={{
                                width: 'calc(50%)',
                                transform: activeTab === "completed" ? 'translate(100%, 0px)' : 'translate(0%, 0px)'
                            }} />
                            <div className={`btn ${activeTab === "active" ? "active" : ""}`} onClick={() => setActiveTab("active")}>
                                <div className="text">Active</div>
                            </div>
                            <div className={`btn ${activeTab === "completed" ? "active" : ""}`} onClick={() => setActiveTab("completed")}>
                                <div className="text">Completed</div>
                            </div>
                        </div>
                    </div>

                    {/* Active Tab */}
                    <div className={`${activeTab === "active" ? 'd-block' : 'd-none'}`} style={{transform: activeTab === "completed" ? 'translate(-100%, 0px)' : 'translate(0%, 0px)'}}>
                        {activeTurnovers.length > 0 ? (
                            <ul className="ticket-wrap">
                                {activeTurnovers.map(turnover => {
                                    const progress = turnover.required_turnover > 0 ? (turnover.remaining_turnover / turnover.required_turnover) * 100 : 0;
                                    return (
                                        <li className="ticket completed" key={turnover.id}>
                                            <div className="ticket-inner">
                                                <div className="ticket-inner-left">
                                                <div className="title">{turnover.transaction?.promotion?.name || turnover.transaction?.payment_method?.name || 'Regular Deposit '}</div>

                                                    <div className="detail">
                                                        <div className="date">Event ends in : {formatDate(turnover.created_at)}</div>
                                                        <div className="detail-btn"><a>Details</a></div>
                                                    </div>
                                                    <div className="discount">
                                                        <div className="amount">{turnover.required_turnover}</div>
                                                    </div>
                                                    <div className="progress-bar">
                                                        <div className="bar"><div className="bar-inner" style={{ width: `${progress}%` }} /></div>
                                                        <div className="number">
                                                            <span>৳{turnover.remaining_turnover}</span>
                                                            <span>{`${progress.toFixed(2)}%`}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ticket-inner-right">
                                                    <div className="text">Active</div>
                                                </div>
                                            </div>
                                            <div className="ticket-deco open-pop">
                                                <div className="line" />
                                                <div className="line" />
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : (
                            <div className="no-result">
                                <div className="pic">
                                    <img alt="no-data" src="https://img.j189eb.com/jb/h5/assets/images/no-data.png?v=1729676340443&source=mcdsrc" loading="lazy" />
                                </div>
                                <div className="text">No Data</div>
                            </div>
                        )}
                    </div>

                    {/* Completed Tab */}
                    <div className={`${activeTab === "completed" ? 'd-block' : 'd-none'}`} style={{transform: activeTab === "completed" ? 'translate(0%, 0px)' : 'translate(-100%, 0px)'}}>
                        {completedTurnovers.length > 0 ? (
                            <ul className="ticket-wrap">
                                {completedTurnovers.map(turnover => (
                                    <li className="ticket completed" key={turnover.id}>
                                        <div className="ticket-inner">
                                            <div className="ticket-inner-left">
                                                <div className="title">{turnover.transaction?.promotion?.name || turnover.transaction?.payment_method?.name || 'Regular Deposit '}</div>
                                                <div className="detail">
                                                    <div className="date">Event ends in : {formatDate(turnover.created_at)}</div>
                                                    <div className="detail-btn"><a>Details</a></div>
                                                </div>
                                                <div className="discount">
                                                    <div className="amount">{turnover.required_turnover}</div>
                                                </div>
                                                <div className="progress-bar">
                                                    <div className="bar"><div className="bar-inner" style={{ width: '100%' }} /></div>
                                                    <div className="number"><span>৳{turnover.required_turnover}</span><span>100%</span></div>
                                                </div>
                                            </div>
                                            <div className="ticket-inner-right">
                                                <div className="text">
                                                    <span className="item-icon" style={{ backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-check-type01.svg")' }} /> Completed
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ticket-deco open-pop">
                                            <div className="line" />
                                            <div className="line" />
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div className="no-result">
                                <div className="pic">
                                    <img alt="no-data" src="https://img.j189eb.com/jb/h5/assets/images/no-data.png?v=1729676340443&source=mcdsrc" loading="lazy" />
                                </div>
                                <div className="text">No Data</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Toolbar/>
        </div>
    );
}

export default TurnoverRecord;
