import React, { useState, useEffect } from "react";
import UniversalBack from "../../common/mobile/UniversalBack";
import NoData from "../../common/mobile/NoData";
import { Modal } from "react-bootstrap";  

function BettingRecord() {
    const [activeTab, setActiveTab] = useState("settle");
    const [providers, setProviders] = useState([]);
    const [gameTypes, setGameTypes] = useState([]);
    const [bettingRecords, setBettingRecords] = useState([]);
    const [exchangeRecords, setExchangeRecords] = useState([]);  // New state for exchange records
    const [selectedPlatform, setSelectedPlatform] = useState("All");
    const [selectedType, setSelectedType] = useState("All");
    const [selectedDate, setSelectedDate] = useState("Today");
    const [isCurrentTab, setIsCurrentTab] = useState(true);

    const [showModal, setShowModal] = useState(false);
    const [selectedExchangeRecord, setSelectedExchangeRecord] = useState(null);

    

    const filterOpen = () => {
        document.querySelector('.searchpage').classList.add('active');
        document.querySelector('.searchpage-bar').classList.add('active');
    };

    const filterClose = () => {
        document.querySelector('.searchpage').classList.remove('active');
        document.querySelector('.searchpage-bar').classList.remove('active');
    };

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            try {
                // Fetch data for providers, game types, and betting records
                const [providersResponse, typesResponse, recordsResponse, exchangeResponse] = await Promise.all([
                    fetch(`${process.env.REACT_APP_API_URL}/api/get-provider`, { headers }),
                    fetch(`${process.env.REACT_APP_API_URL}/api/get-type`, { headers }),
                    fetch(`${process.env.REACT_APP_API_URL}/api/betting-record`, { headers }),
                    fetch(`${process.env.REACT_APP_API_URL}/api/exchange-hub`, { headers }),  // Fetch exchange data
                ]);
        
                if (providersResponse.ok && typesResponse.ok) {
                    const providersData = await providersResponse.json();
                    const typesData = await typesResponse.json();
                    setProviders(providersData.data);
                    setGameTypes(typesData.types);
                }
        
                // Handle betting record response (check if no bets)
                if (recordsResponse.ok) {
                    const recordsData = await recordsResponse.json();
                    setBettingRecords(recordsData);
                } else {
                    // Handle case where user has no bets (400 response)
                    const recordsError = await recordsResponse.json();
                    console.warn(recordsError.message); // Log "User hasn't bet" message or show it in UI
                    setBettingRecords([]); // Set an empty array or handle it accordingly
                }
        
                // Handle exchange-hub response (check if no exchange records)
                if (exchangeResponse.ok) {
                    const exchangeData = await exchangeResponse.json();
                    setExchangeRecords(exchangeData.data);  // Set exchange data
                } else {
                    const exchangeError = await exchangeResponse.json();
                    console.warn(exchangeError.message); // Handle the error message
                    setExchangeRecords([]); // Set empty array or handle it
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        

        fetchData();
    }, []);

    const isDateInRange = (date, range) => {
        const today = new Date();
        const txnDate = new Date(date);
        const lastWeek = new Date(today);
        lastWeek.setDate(lastWeek.getDate() - 7);

        return range === "Today" ? txnDate.toDateString() === today.toDateString() :
               range === "Yesterday" ? txnDate.toDateString() === new Date(today.setDate(today.getDate() - 1)).toDateString() :
               range === "Last 7 days" ? txnDate >= lastWeek && txnDate <= today : true;
    };

    const filteredRecords = bettingRecords.filter(record => {
        const matchesPlatform = selectedPlatform === "All" || record.platform === selectedPlatform;
        const matchesType = selectedType === "All" || record.game_type === selectedType;
        const matchesDate = isDateInRange(record.created_at, selectedDate);
        return matchesPlatform && matchesType && matchesDate;
    });

    const filteredExchangeRecords = exchangeRecords.filter(record => {
        const matchesPlatform = selectedPlatform === "All" || record.platform === selectedPlatform;
        const matchesType = selectedType === "All" || record.game_type === selectedType;
        const matchesDate = isDateInRange(record.created_at, selectedDate);
        return matchesPlatform && matchesType && matchesDate;
    });

    const groupedRecords = filteredRecords.reduce((acc, record) => {
        const date = new Date(record.created_at).toDateString();
        if (!acc[date]) acc[date] = [];
        acc[date].push(record);
        return acc;
    }, {});

    const groupedExchangeRecords = filteredExchangeRecords.reduce((acc, record) => {
        const date = new Date(record.created_at).toDateString();
        if (!acc[date]) acc[date] = [];
        acc[date].push(record);
        return acc;
    }, {});
    const handleExchangeRecordClick = (record) => {
        setSelectedExchangeRecord(record);
        setShowModal(true);
    };

    
    const closePopup = () => setSelectedExchangeRecord(null);

    // Toggle between tabs in transaction details
    const toggleTab = () => setIsCurrentTab(!isCurrentTab);

    return (
        <div className="main-router-wrapper">
            <header id="header" className="login player">
                <div className="header-left-btn-group">
                    <UniversalBack />
                </div>
                <div className="header-title">Betting Records</div>
                <div className="header-right-btn-group">
                    <a name="helpCenter" className="service-btn">
                        <span className="item-icon" style={{ maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/index-theme-icon/header-service-icon.svg?v=1729676340443")' }} />
                    </a>
                </div>
            </header>

            <div className="wrap">
                <div className="content fixed-tab player-content">
                    <div className="tab-btn-section">
                        <div className="tab-btn tab-btn-page">
                            <div className="line" style={{ width: 'calc(50%)', transform: activeTab === "exchange" ? 'translate(100%, 0px)' : 'translate(0%, 0px)' }} />
                            <div className={`btn ${activeTab === "settle" ? "active" : ""}`} onClick={() => setActiveTab("settle")}>
                                <div className="text">Settled</div>
                            </div>
                            <div className={`btn ${activeTab === "exchange" ? "active" : ""}`} onClick={() => setActiveTab("exchange")}>
                                <div className="text">Exchange</div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-content tab-content-page">
                        <div className="ng-trigger ng-trigger-tabPageTriggerAni">
                            <div className="ng-trigger ng-trigger-staggerFadeAnimation tab filter-tab">
                                <ul className="item-ani">
                                    <li className="active">{selectedDate}</li>
                                </ul>
                                <div onClick={filterOpen} className="btn search-btn">
                                    <span className="item-icon" style={{ maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/index-theme-icon/games-filter-icon.svg?v=1729676340443")' }} />
                                </div>
                            </div>
                            <div className="searchpage">
                                <div onClick={filterClose} className="search-top-info">
                                    <div className="back">
                                        <span className="item-icon" style={{ maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-arrow-type09.svg?v=1729676340443")' }} />
                                        Back
                                    </div>
                                    <input type="text" className="ng-untouched ng-pristine" placeholder="Betting Record Filter" disabled style={{ backgroundImage: 'url("")' }} />
                                </div>
                                <div className="searchpage-main">
                                    <div className="search-checkbox-group check-group">
                                        <h2>Platform</h2>
                                        <ul>
                                            {providers.map(provider => (
                                                <li key={provider.platform}>
                                                    <input type="checkbox" id={`checkbox-${provider.platform}`} name={provider.platform} 
                                                        onChange={() => setSelectedPlatform(provider.platform === selectedPlatform ? "All" : provider.platform)} 
                                                        checked={selectedPlatform === provider.platform} />
                                                    <label htmlFor={`checkbox-${provider.platform}`}>{provider.platform}</label>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="search-checkbox-group check-group">
                                        <h2>Game Type</h2>
                                        <ul>
                                            {gameTypes.map(gameType => (
                                                <li key={gameType}>
                                                    <input type="checkbox" id={`checkbox-${gameType}`} name={gameType} 
                                                        onChange={() => setSelectedType(gameType === selectedType ? "All" : gameType)} 
                                                        checked={selectedType === gameType} />
                                                    <label htmlFor={`checkbox-${gameType}`}>{gameType}</label>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="search-checkbox-group check-group">
                                        <h2>Date</h2>
                                        <ul>
                                            <li>
                                                <input type="radio" id="date-today" name="date-filter" 
                                                    onChange={() => setSelectedDate("Today")} 
                                                    checked={selectedDate === "Today"} />
                                                <label htmlFor="date-today">Today</label>
                                            </li>
                                            <li>
                                                <input type="radio" id="date-yesterday" name="date-filter" 
                                                    onChange={() => setSelectedDate("Yesterday")} 
                                                    checked={selectedDate === "Yesterday"} />
                                                <label htmlFor="date-yesterday">Yesterday</label>
                                            </li>
                                            <li>
                                                <input type="radio" id="date-last7days" name="date-filter" 
                                                    onChange={() => setSelectedDate("Last 7 days")} 
                                                    checked={selectedDate === "Last 7 days"} />
                                                <label htmlFor="date-last7days">Last 7 days</label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="searchpage-bar">
                                    <button className="button" onClick={filterClose}>Confirm</button>
                                </div>
                            </div>

                            {/* Display betting records or exchange records */}
                            <div className={`${activeTab === "settle" ? 'd-block' : 'd-none'}`}>
                                <div className="inner-wrap">
                                    <div className="inner-box">
                                        <div className="record-item item-title transaction-record-list">
                                            <div className="item type">Platform</div>
                                            <div className="item amount">Game Type</div>
                                            <div className="item status">Turnover</div>
                                            <div className="item time">Profit/Loss</div>
                                        </div>
                                        {Object.keys(groupedRecords).length > 0 ? (
                                            Object.keys(groupedRecords).map(date => (
                                                <div key={date} className="list list-betting-record">
                                                    <div className="date-title">
                                                        <div className="date">
                                                            <span className="item-icon" style={{ maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-calendar-type02.svg?v=1730201806250")' }} />
                                                            {date}
                                                        </div>
                                                        <div className="time-zone">GMT+6</div>
                                                    </div>
                                                    <div className="list-content">
                                                        {groupedRecords[date].map((record, index) => (
                                                            <div key={index} className="record-item transaction-record-list no-detail-info">
                                                                <div className="item platform">{record.platform}</div>
                                                                <div className="item type">{record.game_type}</div>
                                                                <div className="item bet">{record.bet_amount}</div>
                                                                <div className="item profit">{record.winAmount === 0 ? record.bet_amount : record.winAmount}</div>
                                                                <div className="list-arrow" 
                                                                style={{display: 'block', 
                                                                maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-arrow-type09.svg?v=1730201806250")'}} />
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <NoData />
                                        )}
                                    </div>
                                </div>
                            </div>

                            {/* Display exchange records */}
                            <div className={`${activeTab === "exchange" ? 'd-block' : 'd-none'}`}>
                                <div className="inner-wrap">
                                    <div className="inner-box">
                                        <div className="record-item item-title transaction-record-list">
                                            <div className="item type">Platform</div>
                                            <div className="item amount">Game Type</div>
                                            <div className="item status">Turnover</div>
                                            <div className="item time">Profit/Loss</div>
                                        </div>
                                        {Object.keys(groupedExchangeRecords).length > 0 ? (
                                            Object.keys(groupedExchangeRecords).map(date => (
                                                <div key={date} className="list list-exchange-record">
                                                    <div className="date-title">
                                                        <div className="date">
                                                            <span className="item-icon" style={{ maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-calendar-type02.svg?v=1730201806250")' }} />
                                                            {date}
                                                        </div>
                                                        <div className="time-zone">GMT+6</div>
                                                    </div>
                                                    <div className="list-content">
                                                        {groupedExchangeRecords[date].map((record, index) => (
                                                            <div key={index} className="record-item transaction-record-list no-detail-info" onClick={() => handleExchangeRecordClick(record)}>
                                                                <div className="item platform"> {record.event_name}</div>
                                                                <div className="item type">
                                                                {record.bet_type}
                                                                </div>


                                                                <div className="item bet">{Math.abs(record.stake)}
                                                                </div>
                                                                <div
                                                                    className={`item`}
                                                                    style={{
                                                                        color: record.status === 'open' ? 'inherit' : (record.status === "WON" ? 'green' : 'red')
                                                                    }}
                                                                    >
                                                                    {Math.abs(record.net_profit_loss)}
                                                                    </div>


                                                                <div className="list-arrow" 
                                                                style={{display: 'block', 
                                                                maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-arrow-type09.svg?v=1730201806250")'}} />
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <NoData />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {selectedExchangeRecord && (
               <>
                <div className="pop-transaction-records-details active">
                   
                    <a onClick={closePopup} className="btn-closed" style={{ maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-cross-type01.svg?v=1730201806250")' }} />
                    <div className="header">Exchange Record Details</div>
                    <div className={`content pop-content  ${selectedExchangeRecord.status === "WON" ? "success" : selectedExchangeRecord.status === "LOST" ? "fail" : "processing"}`}>
                        <div className="tab-btn-section tab-btn-wrap" onClick={toggleTab}>
                            <div className="tab-btn tab-btn-bar">
                                <div className="line" style={{ width: 'calc(50%)', transform: isCurrentTab ? "translate(0%, 0px)" : "translate(100%, 0px)" }} />
                                <div className="btn" data-tab-current="current">
                                    <img className="icon" alt="icon-timeline" src="https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-timeline.svg?v=1730201806250" loading="lazy" />
                                </div>
                                <div className="btn">
                                    <img className="icon" alt="icon-table" src="https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-table.svg?v=1730201806250" loading="lazy" />
                                </div>
                            </div>
                        </div>
                        <div className="tab-content tab-content-page">
                            <div className="inner-wrap">
                                {/* Progress Tab */}
                                <div className="inner-box" style={{ display: isCurrentTab ? "block" : "none" }}>
                                    <div className="transaction-details-wrap">
                                        <div className="title">
                                            <h3>Exchange Record</h3>
                                            <div className="tags">{selectedExchangeRecord.status === "WON" ? "WON" : selectedExchangeRecord.status === "LOST" ? "LOST" : "processing"}</div>
                                            
                                        </div>
                                        <div className="details-box">
                                            <div className="info">
                                                <div className="name">Event Name</div>
                                                <div className="value">{selectedExchangeRecord.event_name}</div>
                                            </div>
                                            <div className="info">
                                                <div className="name">Transaction id</div>
                                                <div className="value">{selectedExchangeRecord.transaction_id}</div>
                                            </div>
                                            <div className="info">
                                                <div className="name">Amount</div>
                                                <div className="value">{selectedExchangeRecord.stake}</div>
                                            </div>
                                      </div>
                                       
                                    </div>
                                </div>
                                
                                {/* Details Tab */}
                                <div className="inner-box" style={{ display: !isCurrentTab ? "block" : "none" }}>
                                    <div className="transaction-details-wrap">
                                        <div className="title">
                                            <h3>Exchange Record Details</h3>
                                            <div className="tags">{selectedExchangeRecord.status === "WON" ? "WON" : selectedExchangeRecord.status === "LOST" ? "LOST" : "processing"}</div>
                                        </div>
                                        <div className="details-box">
                                            <div className="info">
                                                <div className="name">Event Name</div>
                                                <div className="value">{selectedExchangeRecord.event_name}</div>
                                            </div>
                                            
                                            <div className="info">
                                                <div className="name">Game Type</div>
                                                <div className="value">{selectedExchangeRecord.bet_type}</div>
                                            </div>
                                            <div className="info">
                                                <div className="name">Market Name</div>
                                                <div className="value">{selectedExchangeRecord.market_name} </div>
                                            </div>
                                            <div className="info">
                                                <div className="name">Selection Name</div>
                                                <div className="value">{selectedExchangeRecord.selection_name} </div>
                                            </div>
                                            <div className="info">
                                                <div className="name">Bet Type</div>
                                                <div className="value">{selectedExchangeRecord.bet_type} </div>
                                            </div>
                                            
                                            <div className="info">
                                                <div className="name">Stake</div>
                                                <div className="value">{selectedExchangeRecord.stake} </div>
                                            </div>
                                            <div className="info">
                                                <div className="name">Potential Profit</div>
                                                <div className="value">{selectedExchangeRecord.potential_profit} </div>
                                            </div>
                                            <div className="info">
                                                <div className="name">Potential Liability</div>
                                                <div className="value">{selectedExchangeRecord.potential_liability} </div>
                                            </div>
                                            <div className="info">
                                                <div className="name">Odds</div>
                                                <div className="value">{selectedExchangeRecord.odds} </div>
                                            </div>
                                            <div className="info">
                                                <div className="name">Runs</div>
                                                <div className="value">{selectedExchangeRecord.run} </div>
                                            </div>
                                            
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="pop-bg d-block" onClick={closePopup} />
               </>
            )}
        </div>
    );
}

export default BettingRecord;
