import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const GameRun = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { iframeUrl, fromParams } = location.state || {};
  const username = localStorage.getItem('username');
  const iframeRef = useRef(null);
  const [logo, setLogo] = useState([]);
      useEffect(() => {
          const fetchPromotions = async () => {
              try {
                  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/logo`);
                  if (!response.ok) {
                      throw new Error("Failed to fetch promotions");
                  }
                  const data = await response.json();
                  setLogo(data[0].logo);
              } catch (error) {
                  console.error("Error fetching promotions:", error);
              } 
          };
      
          fetchPromotions();
      }, []);

  const handleBack = () => {
    if (fromParams) {
      const { type, system, title } = fromParams;
      navigate(`/games?type=${encodeURIComponent(type)}&system=${encodeURIComponent(system)}&title=${encodeURIComponent(title)}`);
    } else {
      navigate(-1); // fallback
    }
  };

  return (
    <div className="cdk-overlay-container">
  <div className="cdk-overlay-backdrop dialog-backdrop cdk-overlay-backdrop-showing" />
  <div className="cdk-global-overlay-wrapper" dir="ltr" style={{ justifyContent: "center", alignItems: "center" }}>
    <div id="cdk-overlay-3" className="cdk-overlay-pane dialog-panel" style={{ position: "static" }}>
      <div className="popup" id="dialog-3">
        <div className="popup__header"></div>
        <div className="popup__content">
          <div className="launch-game-content ng-star-inserted">
            <div className="launch-game-loading select-logo"
              style={{ display: "none" }}>
              <div className="loading-bg" style={{ display: "none" }} />
              <div className="loader-round" style={{ opacity: 0 }}>
                <svg
                  version="1.2"
                  baseProfile="tiny"
                  id="Layer_1"
                  x="0px"
                  y="0px"
                  width="471.197px"
                  height="471.197px"
                  viewBox="0 0 471.197 471.197"
                  overflow="inherit"
                  preserveAspectRatio="xMidYMid meet">
                  <defs>
                    <linearGradient id="loading-bar-color">
                      <stop
                        offset="5%"
                        className="loading-stop-color"
                        style={{ stopColor: "rgba(232,206,120, 0.8)" }}/>
                      <stop
                        offset="100%"
                        className="loading-end-color"
                        style={{ stopColor: "rgba(232,206,120, 1)" }}/>
                    </linearGradient>
                  </defs>
                  <g id="loader">
                    <circle
                      id="dark"
                      fill="transparent"
                      strokeWidth={36}
                      strokeLinecap="round"
                      strokeMiterlimit={1}
                      cx="235.582"
                      cy="235.114"
                      r="212.599"
                      className="circle"
                    />
                    <circle
                      id="white"
                      fill="transparent"
                      strokeWidth={36}
                      strokeLinecap="round"
                      strokeMiterlimit={10}
                      cx="235.582"
                      cy="235.114"
                      r="212.599"
                      className="circle"
                    />
                  </g>
                </svg>
                <div
                  className="center-logo-bg"
                  style={{
                    boxShadow:
                      "linear-gradient(180deg, rgba(5, 5, 5, 0.7) 20%, rgba(232,206,120, 0.8) 100%)",
                  }}>
                  <img
                    className="center-logo ng-star-inserted"
                    alt="provider-awcme1sport"
                    src={`${process.env.REACT_APP_API_URL}/storage/${logo}`}
                    loading="lazy"/>
                </div>
              </div>
            </div>
            <div className="launch-game-page" style={{ display: "block" }}>
              <div className="site-top" style={{ display: "block" }}>
                <div className="site-menu no-deposit">
                  <div
                   onClick={handleBack}
                    className="btn"
                    style={{ transform: "translate(0px, 0px)", opacity: 1 }}
                  >
                    <div className="btn-cross" />
                  </div>
                  <div
                    className="info-wrap"
                    style={{
                      display: "block",
                      opacity: 1,
                      transform: "skew(-10.0002deg, 0deg)",
                    }}
                  >
                    <div className="info-block">
                      <div className="info-title">USER</div>
                      <div className="info-value">
                        {username}
                        <span className="info-value-sub ng-star-inserted"></span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="f-logo-bg"
                    style={{ transform: "translate(0px, 0px)", opacity: 1 }}
                  >
                    <div
                      className="f-logo logo-image"
                      style={{ backgroundImage: `url('${process.env.REACT_APP_API_URL}/storage/${logo}')` }}
                    />
                    <div
                      className="light-ring"
                      style={{
                        backgroundImage:
                          'url("https://img.j189eb.com/jb/h5/assets/images/launch-game/icon-light-ring.svg?v=1738666148080")',
                        transform: "translate(0px, 0px)",
                        opacity: 1,
                      }}
                    />
                  </div>
                  <div
                    className="btn"
                    style={{ transform: "translate(0px, 0px)", opacity: 1 }}
                  >
                    <div
                      className="btn-deposit"
                      style={{
                        maskImage:
                          'url("/assets/images/launch-game/icon-deposit.svg")',
                      }}
                    />
                    <div
                      className="light-ring"
                      style={{
                        backgroundImage:
                          'url("https://img.j189eb.com/jb/h5/assets/images/launch-game/icon-light-ring.svg?v=1738666148080")',
                        transform: "translate(0px, 0px)",
                        opacity: 1,
                      }}
                    />
                  </div>
                  <div
                    className="menu-bg"
                    style={{ opacity: 1, height: "100%", width: "100%" }}
                  >
                    <div
                      className="highlight"
                      style={{
                        boxShadow:
                          "inset 0 -1.5px 2.5px 0 rgba(232,206,120, 1), inset 0 1px 3px 0 rgba(255,255,255,.5)",
                        backgroundImage:
                          "inset 0 -1.5px 2.5px 0 rgba(232,206,120, 1), inset 0 1px 3px 0 rgba(255,255,255,.5)",
                      }}
                    />
                  </div>
                </div>
                <div
                  className="top-bg"
                  style={{ transform: "translate(0px, -100px)" }}
                />
              </div>
              <div
                className="site-bg"
                style={{
                  background:
                    "linear-gradient(rgba(5, 5, 5, 0.7) 20%, rgba(232, 206, 120, 0.8) 100%)",
                  display: "block",
                  transform: "translate(0px, 0px)",
                }}
              />
              <div
                className="launch-game-wrap show"
                style={{ transform: "translate(0px, 0px)" }}
              >
                <iframe
                  name="myiFrame"
                  marginHeight="0px"
                  marginWidth="0px"
                  height="603px"
                  width="375px"
                  allowFullScreen
                  className="launch-game-iframe"
                  style={{ border: "0px #ffffff none" }}
                  scrolling="yes"
                  src={iframeUrl}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  );
};

export default GameRun;
