import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function MAccount({userData, sociallink, general}) {
    const [isLoggedIn] = useState(!!localStorage.getItem('token'));
    const [showBalance, setShowBalance] = useState(true);
    const [isRefreshing, setIsRefreshing] = useState(false); // Track refresh status
    const navigate = useNavigate();


    // Toggle balance visibility
    const toggleBalanceVisibility = () => {
        setShowBalance((prevShowBalance) => !prevShowBalance);
    };

    // Logout function
    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('balance');
        localStorage.removeItem('remote_id');
        localStorage.removeItem('username');
        localStorage.removeItem('exchangeToken');
        navigate('/');
    };

    return (
        <div className="main-router-wrapper ng-trigger ng-trigger-routeFullPageAni">
            <div className="member-menu ng-star-inserted active">
               <Link to="/" className="bg-tran">  <div className="close ng-star-inserted" /> </Link>
                <div className="member-header bonuswallet ng-star-inserted" style={{ backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/member-header-bg.png?v=1729676340443")' }}>
                    <div className="member-header-content">
                        <div className="pic pic-lv1 ng-star-inserted" style={{ backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/vip/bdt/normal.png?v=1729676340443")' }}></div>
                        <div className="infor">
                            <div className="account">
                                <span className="ng-star-inserted">{userData ? userData.username : 'User'}</span>
                            </div>
                            <div className="vip-points active ng-star-inserted">
                                VIP Points (VP)<span>{userData ? userData.vip_coin : '0'}</span> 
                                <Link to="/vip" className="myvip-text"> 
                                    My VIP <span className="item-icon" style={{ maskImage: 'url("/assets/images/icon-set/player/vip/icon-arrow.svg")' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="member-menu-content bonuswallet ng-star-inserted">
                    <div className="member-menu-box balance-box ng-star-inserted">
                        <div className="balance balance-row">
                            <div className="text">
                                Main Wallet
                                <div
                                    className={`icon refresh ${isRefreshing ? 'active' : ''}`}
                                  
                                    style={{ maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-refresh-type01.svg?v=1729676340443")' }}
                                />
                                <div
                                    className="icon eyes"
                                    onClick={toggleBalanceVisibility}
                                    style={{
                                        maskImage: showBalance
                                            ? 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-eye-open-type01.svg?v=1729676340443")'
                                            : 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-eye-close-type01.svg?v=1729676340443")',
                                    }}
                                />
                            </div>
                            <span className="amount totalBalanceWallet">
                                <i>{showBalance ? `৳ ${userData?.balance || 0}` : '***'}</i>
                            </span>
                        </div>
                    </div>
                    <div className="member-menu-box member-list ng-star-inserted">
                    <div className="title">
                        <h2><span>Funds</span></h2>
                    </div>
                    <ul className="align-center">
                        <li className="deposit ng-star-inserted">
                            <Link to="/deposit">
                                <span
                                    className="item-icon ng-star-inserted"
                                    style={{ backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/theme-icon/icon-deposit.png?v=1729676340443")' }}
                                />
                                <p>Deposit</p>
                            </Link>
                        </li>
                        <li className="withdrawal ng-star-inserted">
                            <Link to="/withdrawal">
                                <span
                                    className="item-icon ng-star-inserted"
                                    style={{ backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/theme-icon/icon-withdrawal.png?v=1729676340443")' }}
                                />
                                <p>Withdrawal</p>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="member-menu-box member-list ng-star-inserted">
                    <div className="title">
                        <h2><span>History</span></h2>
                    </div>
                    <ul className="align-center">
                        <li className="total ng-star-inserted">
                            <Link to="/betting-record">
                                <span
                                    className="item-icon ng-star-inserted"
                                    style={{ backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/theme-icon/icon-bet-records.png?v=1729676340443")' }}
                                />
                                <p>Betting Records</p>
                            </Link>
                        </li>
                        <li className="water ng-star-inserted">
                            <Link to="/turnover-record">
                                <span
                                    className="item-icon ng-star-inserted"
                                    style={{ backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/theme-icon/icon-turnover.png?v=1729676340443")' }}
                                />
                                <p>Turnover</p>
                            </Link>
                        </li>
                        <li className="history ng-star-inserted">
                            <Link to="/transaction-record">
                                <span
                                    className="item-icon ng-star-inserted"
                                    style={{ backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/theme-icon/icon-records.png?v=1729676340443")' }}
                                />
                                <p>Transaction Records</p>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="member-menu-box member-list ng-star-inserted">
                    <div className="title">
                        <h2><span>Profile</span></h2>
                    </div>
                    <ul>
                        <li className="account ng-star-inserted">
                            <Link to="/profile">
                                <span
                                    className="item-icon ng-star-inserted"
                                    style={{ backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/theme-icon/icon-profile.png?v=1729676340443")' }}
                                />
                                <p>Personal Info</p>
                            </Link>
                        </li>
                        <li className="resetpasswords ng-star-inserted">
                            <Link to="/reset-password">
                                <span
                                    className="item-icon ng-star-inserted"
                                    style={{ backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/theme-icon/icon-resetpasswords.png?v=1729676340443")' }}
                                />
                                <p>Reset password</p>
                            </Link>
                        </li>
                        <li className="mail ng-star-inserted">
                            <Link to="/inbox">
                                <span
                                    className="item-icon ng-star-inserted"
                                    style={{ backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/theme-icon/icon-inbox.png?v=1729676340443")' }}
                                />
                                <p>Inbox</p>
                            </Link>
                        </li>
                        <li className="recommendfriend ng-star-inserted">
                            <Link to="/refrrel-program">
                                <span
                                    className="item-icon ng-star-inserted"
                                    style={{ backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/theme-icon/icon-referral.png?v=1729676340443")' }}
                                />
                                <p>Referral</p>
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className="member-menu-box member-list csicons ng-star-inserted">
                        <div className="title">
                            <h2><span>Contact Us</span></h2>
                        </div>
                        <ul className="align-center">
                            <li className="whatsapp ng-star-inserted">
                                <a href={`${sociallink.whatsapp}`} >
                                    <span className="item-icon ng-star-inserted" style={{ backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/theme-icon/icon-whatsapp.png?v=1729676340443")' }} />
                                    <p className="ng-star-inserted">WhatsApp</p>
                                </a>
                            </li>
                            <li  className="facebook-messenger ng-star-inserted">
                                <a href={`${sociallink.facebook}`} mcdneuaclick>
                                    <span className="item-icon ng-star-inserted" style={{ backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/theme-icon/icon-facebook-messenger.png?v=1729676340443")' }} />
                                    <p className="ng-star-inserted">Facebook</p>
                                </a>
                            </li>
                            <li  className="facebook-messenger ng-star-inserted">
                                <a href={`${sociallink.telegram}`}>
                                    <span className="item-icon ng-star-inserted" style={{ backgroundImage: 'url("./icons/icon-telegram.png")' }} />
                                    <p className="ng-star-inserted">Telegram</p>
                                </a>
                            </li>
                            <li className="email ng-star-inserted">
                                <a href={`mailto:${general?.support_mail || ''}`}>
                                    <span className="item-icon ng-star-inserted" style={{ backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/theme-icon/icon-email.png?v=1729676340443")' }} />
                                    <p className="ng-star-inserted">Email</p>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="member-menu-logout">
                        <a onClick={logout}>
                            <span
                                className="item-icon ng-star-inserted"
                                style={{ maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/index-theme-icon/header-logout-icon.svg?v=1729676340443")' }}
                            />
                            <div className="text"><p>Log out</p></div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MAccount;
