import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Splash from '../../../splash/Splash';



const FeatureGames = ({slidersFeatures}) => {
 
        const [isLoading, setIsLoading] = useState(false);
      const [isLoggedIn] = useState(!!localStorage.getItem('token'));
      const [isMobile] = useState(false);
      const navigate = useNavigate();
      const [error, setError] = useState(null);

    // Fetch games data
   
    
    const handleGameClick = async (gameCode) => {
        if(!isLoggedIn) {
          navigate('/login');
        }
        setIsLoading(true);
  
          try {
              const token = localStorage.getItem('token');
              const remote_id = localStorage.getItem('remote_id');
              if (!token) {
                  setError('User not logged in');
                  return;
              }
              let parts = gameCode.split('-');

                  
                  let platform = parts[0];   
                  let gameType = parts[1];  
                 
            
              const response = await fetch(`${process.env.REACT_APP_API_URL}/api/game-run`, {
                  method: 'POST',
                  headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${token}`,
                  },
                 
                  body: JSON.stringify({
                     userId : remote_id,
                     externalURL: 'http://www.worldbaji.live',
                     isMobileLogin: isMobile,
                     gameCode:gameCode,
                     gameType: gameType,
                     platform: platform
                     
                     
                  }),
                 
              });
  
              const data = await response.json();
              if (response.ok) {
                  console.log(data.url);
                  navigate('/gamerun', { state: { iframeUrl: data.url } });
              } else {
                  setError(data.message || 'An unexpected error occurred');
              }
          } catch (error) {
              setError('An unexpected error occurred');
          }
          finally {
              setIsLoading(false);
          }
      };
      if(isLoading) {
        return(
         <Splash/>
        );
      }

    return (
        
            <div className="recommend feature-games">
                <div className="recommend-title">
                    <h2>Featured Games</h2>
                </div>
                <div className="recommend-bg games">
                    <div className="recommend-main games-main">
                        {slidersFeatures.length > 0 ? (
                            slidersFeatures.map((game, index) => (
                                <div onClick={() => handleGameClick(game.link)} className="games-box" key={index}>
                                    <div className="pic">
                                        <Link>
                                            <img 
                                                alt={game.name} 
                                                src={`${process.env.REACT_APP_API_URL}/storage/${game.thubmnail}`} 
                                                loading="lazy" 
                                            />
                                        </Link>
                                    </div>
                                    <div className="text">
                                        <h3>{game.name}</h3>
                                        <input type='hidden' value={error}/>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p></p>
                        )}
                    </div>
                </div>
            </div>
        
    );
};

export default FeatureGames;
