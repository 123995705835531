import React, { useState, useEffect } from 'react';
import Splash from '../../../../splash/Splash';

function APIS({ setGlobalData }) {
    const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
    const [isLoadingPublic, setIsLoadingPublic] = useState(true);
    const [isLoadingPrivate, setIsLoadingPrivate] = useState(isLoggedIn);

    const [error, setError] = useState(null);

    // Define API data states
    const [apiData, setApiData] = useState({
        sliders: { main: [], favourite: [], feature: [] },
        socialLinks: [],
        general: {},
        paymentMethods: [],
        promotions: { activePromotion: [], displayPromotion: [] },
        userData: null,
        transactions: [], 
        turnovers: [], 
    });

    // Fetch Public APIs (No login required)
    useEffect(() => {
        const fetchPublicData = async () => {
            console.time('Public API Fetch Time');
            setIsLoadingPublic(true);

            try {
                const startTime = performance.now();

                const apiResponses = await Promise.all([
                    fetch(`${process.env.REACT_APP_API_URL}/api/sliders`).then(res => res.json()),
                    fetch(`${process.env.REACT_APP_API_URL}/api/sociallink`).then(res => res.json()),
                    fetch(`${process.env.REACT_APP_API_URL}/api/general`).then(res => res.json()),
                    fetch(`${process.env.REACT_APP_API_URL}/api/payment-methods`).then(res => res.json()),
                    fetch(`${process.env.REACT_APP_API_URL}/api/promotion`).then(res => res.json()),
                ]);

                const endTime = performance.now();
                console.log(`Public API calls took: ${(endTime - startTime).toFixed(2)} ms`);

                const [slidersData, socialData, generalData, paymentData, promotionsData] = apiResponses;

                // ✅ Ensure uniqueness of payment methods
                const uniquePaymentMethods = [];
                const seenNames = new Set();

                paymentData.forEach((item) => {
                    if (item.name && !seenNames.has(item.name)) {
                        seenNames.add(item.name);
                        uniquePaymentMethods.push(item);
                    }
                });

                setApiData(prevData => ({
                    ...prevData,
                    sliders: {
                        main: slidersData.filter(slider => slider.status === 'active' && slider.slider_type === 'Main'),
                        favourite: slidersData.filter(slider => slider.status === 'active' && slider.slider_type === 'Favourite'),
                        feature: slidersData.filter(slider => slider.status === 'active' && slider.slider_type === 'Features'),
                    },
                    socialLinks: socialData[0] || {},
                    general: generalData[0] || {},
                    paymentMethods: uniquePaymentMethods, 
                    promotions: {
                        activePromotion: promotionsData.filter(promo => promo.status === 'active'),
                        displayPromotion: promotionsData.filter(promo => promo.status === 'display'),
                    },
                }));

            } catch (error) {
                console.error('Error fetching public data:', error);
                setError('Error fetching public API data');
            } finally {
                setIsLoadingPublic(false);
                console.timeEnd('Public API Fetch Time');
            }
        };

        fetchPublicData();
    }, []);

    // Fetch Private APIs (Login required)
    useEffect(() => {
        const token = localStorage.getItem('token');
        const remoteId = localStorage.getItem('remote_id');

        if (isLoggedIn && token && remoteId) {
            console.time('Private API Fetch Time');
            setIsLoadingPrivate(true);

            const fetchPrivateData = async () => {
                try {
                    const startTime = performance.now();

                    const apiResponses = await Promise.all([
                        fetch(`${process.env.REACT_APP_API_URL}/api/user/show?remote_id=${remoteId}`, {
                            method: 'GET',
                            headers: { Authorization: `Bearer ${token}` },
                        }).then(res => res.json()),
                        fetch(`${process.env.REACT_APP_API_URL}/api/transaction/show?remote_id=${remoteId}`, {
                            method: 'GET',
                            headers: { Authorization: `Bearer ${token}` },
                        }).then(res => res.json()),
                        fetch(`${process.env.REACT_APP_API_URL}/api/turnovers?remote_id=${remoteId}`, {
                            method: 'GET',
                            headers: { Authorization: `Bearer ${token}` },
                        }).then(res => res.json()),
                    ]);

                    

                    const [userData, transactions, turnovers] = apiResponses;

                    setApiData(prevData => ({
                        ...prevData,
                        userData,
                        transactions,
                        turnovers: turnovers.turnover || [],
                    }));

                } catch (error) {
                    console.error('Error fetching private data:', error);
                    setError('Error fetching user data');
                } finally {
                    setIsLoadingPrivate(false);
                    console.timeEnd('Private API Fetch Time');
                }
            };

            fetchPrivateData();
        }
    }, [isLoggedIn]);

    // Pass API data to global state in App.js
    useEffect(() => {
        setGlobalData(apiData);
    }, [apiData, setGlobalData]);

    // **Loading State Handling**
    if (isLoadingPublic) {
        return (
          <Splash/>
        );
    }

    return null;
}

export default APIS;
