import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

const GuestToolbar = () => {
    const [showModal, setShowModal] = useState(false); // State to control modal visibility
    const [language, setLanguage] = useState('English'); // State for selected language

    // Function to handle showing the modal
    const handleShowModal = () => setShowModal(true);

    // Function to handle closing the modal
    const handleCloseModal = () => setShowModal(false);

    // Function to trigger Google Translate
    const translatePage = (lang) => {
        const googleTranslateElement = document.querySelector('.goog-te-combo');
        if (googleTranslateElement) {
            const langCode = lang === 'Bangla' ? 'bn' : 'en'; // Set the language code
            googleTranslateElement.value = langCode;
            googleTranslateElement.dispatchEvent(new Event('change')); // Trigger change event
        }
        setLanguage(lang); // Update the selected language
        handleCloseModal(); // Close the modal
    };

    return (
        <mcd-guest-toolbar>
            <div className="beforelogin havelanguage">
                <div onClick={handleShowModal} className="language-select order-1">
                    <img
                        alt="BD"
                        src="https://img.j189eb.com/jb/h5/assets/images/flag/BD.png?v=1729676340443&source=mcdsrc"
                        loading="lazy"
                    />
                    <p>
                        BDT <br />
                        {language}
                    </p>
                </div>
                
                <div className="login-button order-2">
                    <Link to="/login" className="ng-star-inserted"> Login </Link>
                </div>
                <div className="register-button order-3">
                    <Link to="/signup" className="ng-star-inserted"> Sign up </Link>
                </div>
            </div>

            <Modal className="language-container" show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Choose Language</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="pop-inner">
                        <ul className="language-list">
                            <li className="ng-star-inserted">
                                <div className="left-language-area">
                                    <img
                                        alt="BDT"
                                        src="https://img.j189eb.com/jb/h5/assets/images/flag/BD.png?v=1741697022595&source=mcdsrc"
                                        loading="lazy"
                                    />
                                    <p><span>৳</span> BDT</p>
                                </div>
                                <div className="right-language-area">
                                    <div className="radio-box ng-star-inserted">
                                        <input
                                            onClick={() => translatePage('English')}
                                            checked={language === 'English'} // Make checked based on selected language
                                            type="radio"
                                            name="language"
                                            id="BDT0"
                                        />
                                        <label htmlFor="BDT0">English</label>
                                    </div>
                                    <div className="radio-box ng-star-inserted">
                                        <input
                                            onClick={() => translatePage('Bangla')}
                                            checked={language === 'Bangla'} // Make checked based on selected language
                                            type="radio"
                                            name="language"
                                            id="BDT1"
                                        />
                                        <label htmlFor="BDT1">বাংলা</label>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </Modal.Body>
            </Modal>
        </mcd-guest-toolbar>
    );
};

export default GuestToolbar;
