import React, { useEffect, useState } from 'react';

const GoToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div
      className={`gotop bottom-2 ${isVisible ? 'is-visible' : ''}`}
      style={{ transform: 'translate(-10px, 0px)' }}
      onClick={scrollToTop}
    />
  );
};

export default GoToTop;
