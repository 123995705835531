import React, { useState, useEffect } from "react";
import DepositNotice from "../../notice/mobile/DepositNotice";
import WithdrawNotice from "../../notice/mobile/WithdrawNotice";
import Toolbar from "../../navigation/mobile/Toolbar";
import UniversalBack from "../../common/mobile/UniversalBack";

function Profile() {
    const [userData, setUserData] = useState({
        username: '',
        vip_coin: 0,
        name: '',
        phone: '',
        session_id: '',
        email: '',
        created_at: '',
        dofb: '',
    });
    const [isEditingBirthday, setIsEditingBirthday] = useState(false);
    const [dofb, setDofb] = useState('');
    const [verifyType, setVerifyType] = useState("");
    const [timer, setTimer] = useState(0); // Timer state for countdown
    const [isCodeSent, setIsCodeSent] = useState(false); // Track if verification code is sent
    const [verificationCode, setVerificationCode] = useState('');
    const [phoneNumber, setphoneNumber] = useState('');
    const [getotherContracts, setotherContracts] = useState([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);


    const fetchUserData = async () => {
        const token = localStorage.getItem('token');
        const remoteId = localStorage.getItem('remote_id');
        if (token && remoteId) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/show?remote_id=${remoteId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    const formattedDate = data.created_at ? new Date(data.created_at).toLocaleDateString('en-GB') : 'N/A';

                    setUserData({
                        username: data.username || 'Unknown User',
                        vip_coin: data.vip_coin || 0,
                        name: data.name || 'N/A',
                        phone: data.phone || '',
                        email: data.email || '',
                        session_id: data.session_id || '',
                        created_at: formattedDate,
                        dofb: data.dofb || '',
                    });

                    // Automatically enable birthday editing if dofb is null or empty
                    if (!data.dofb) {
                        setIsEditingBirthday(true);
                    }
                    setDofb(data.dofb || '');
                } else {
                    console.error('Failed to fetch user data');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        }
    };
    const fetchOther = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/get/other/phone`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setotherContracts(data.data || []);
                    console.log(data.data);
                   
                } else {
                    console.error('Failed to fetch user data');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        }
    };
    const updateDofb = async () => {
        const token = localStorage.getItem('token');
        const remoteId = localStorage.getItem('remote_id');
        if (token && remoteId && dofb) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/profile/update`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        remote_id: remoteId,
                        dofb: dofb,
                    }),
                });

                if (response.ok) {
                    const data = await response.json();
                    setUserData((prevUserData) => ({
                        ...prevUserData,
                        dofb: data.dofb || dofb,
                    }));
                    setIsEditingBirthday(false); // Turn off editing mode after updating
                } else {
                    console.error('Failed to update DOFB');
                }
            } catch (error) {
                console.error('Error updating DOFB:', error);
            }
        }
    };

    const verfiyPhoneNumber = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/verify-phone-number`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        verification_code: verificationCode, 
                    }),
                });

                if (response.ok) {
                    const data = await response.json();
                  console.log(data); // Handle the response
                } else {
                    console.error('Failed to send code');
                }
            } catch (error) {
                console.error('Error send code:', error);
            }
        }
    };
    const addverfiyPhoneNumber = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/add/verify-phone-number`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        verification_code: verificationCode, 
                    }),
                });

                if (response.ok) {
                    const data = await response.json();
                  console.log(data); // Handle the response
                } else {
                    console.error('Failed to send code');
                }
            } catch (error) {
                console.error('Error send code:', error);
            }
        }
    };

    const verfiyPhonCode = async () => {
        if (isButtonDisabled) return; // Prevent second click

        setIsButtonDisabled(true); // Disable the button after the first click

        const token = localStorage.getItem('token');
        if (token) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/send-verification-code`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        phone: userData.phone,
                       
                    }),
                });
    
                if (response.ok) {
                    setIsCodeSent(true);
                    setTimer(5 * 60); // Start a 5-minute timer
                    const countdown = setInterval(() => {
                        setTimer((prevTime) => {
                            if (prevTime <= 0) {
                                clearInterval(countdown);
                                return 0;
                            }
                            return prevTime - 1;
                        });
                    }, 1000);
                } else {
                    console.error('Failed to send code');
                }
            } catch (error) {
                console.error('Error sending code:', error);
            }
        } else {
            console.error('Verification code is required');
        }
    };
    const addPhoneNumber = async () => {
        if (isButtonDisabled) return;
    
        setIsButtonDisabled(true);
    
        const token = localStorage.getItem('token');
        if (!token) {
            console.error('User token not found.');
            setIsButtonDisabled(false);
            return;
        }
    
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/add/number`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ phone: phoneNumber }),
            });
    
            if (!response.ok) {
                const errorData = await response.json();
                console.error('Failed to add phone number:', errorData.message || 'Unknown error');
                setIsButtonDisabled(false);
                return;
            }
    
            const verifyResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/add/send-verification-code`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ phone: phoneNumber }),  // এখানে userData.phone নয়, phoneNumber পাঠানো হয়েছে
            });
    
            if (!verifyResponse.ok) {
                const errorData = await verifyResponse.json();
                console.error('Failed to send verification code:', errorData.message || 'Unknown error');
                setIsButtonDisabled(false);
                return;
            }
    
            setIsCodeSent(true);
            setTimer(5 * 60);
    
            let countdown = setInterval(() => {
                setTimer((prevTime) => {
                    if (prevTime <= 0) {
                        clearInterval(countdown);
                        return 0;
                    }
                    return prevTime - 1;
                });
            }, 1000);
    
        } catch (error) {
            console.error('Error sending code:', error);
            setIsButtonDisabled(false);
        }
    };
    

    useEffect(() => {
        fetchUserData();
        fetchOther();
    }, []);

    const OpenPOP = (type) => {
        setVerifyType(type);
        const chatElement = document.querySelector('.pop-get-vcode');
        chatElement.classList.add('active');
        const bg = document.querySelector('.pop-bg');
        bg.classList.add('d-block');
    };

    const ClosePOP = () => {
        setVerifyType("");
        const chatElement = document.querySelector('.pop-get-vcode');
        chatElement.classList.remove('active');
        const bg = document.querySelector('.pop-bg');
        bg.classList.remove('d-block');
    };

    return (
        <div className="main-router-wrapper ng-trigger ng-trigger-routeFullPageAni">
            <header id="header" className="login ng-star-inserted player">
                <div className="header-left-btn-group">
                   <UniversalBack/>
                </div>
                <div className="header-title ng-star-inserted">Personal Info</div>
            </header>
            <div className="wrap">
                <div className="content player-content">
                    <div className="ng-trigger ng-trigger-routeLayoutPageAni">
                        <div className="player-vip-lv1 ng-star-inserted">
                            <div className="player-info-vip" style={{ backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/player/vip/vip-card-bg-1.jpg?v=1729676340443")' }}>
                                <div className="member-pic">
                                    <span className="item-icon ng-star-inserted" style={{ backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/vip/bdt/normal.png?v=1729676340443")' }} />
                                </div>
                                <div className="member-label" style={{ backgroundImage: 'url("PROFILE_VIP_LABEL_LV_1")' }} />
                            </div>
                            <div className="membername-wrap">
                                <div className="membername">{userData.username}</div>
                                <div className="level ng-star-inserted">Normal</div>
                            </div>
                        </div>
                        <div id="profile-vip-div" className="menu-box ng-star-inserted">
                            <div className="vip-area-group">
                                <div className="left-box">
                                    <div className="item">
                                        <h3>VIP Points (VP)</h3>
                                        <div className="points-number">{userData.vip_coin}</div>
                                    </div>
                                </div>
                                <div className="right-box">
                                    <a className="goto-myvip ng-star-inserted" href="/bd/en/member/vip-points-exchange">
                                        <div className="myvip-text">
                                            <p>My VIP</p>
                                            <span className="item-icon" style={{ maskImage: 'url("/assets/images/icon-set/player/vip/icon-arrow.svg")' }} />
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <DepositNotice />
                        <WithdrawNotice />
                        <div className="menu-box ng-star-inserted">
                            <div className="area-group full_name">
                                <div className="item">
                                    <h3>Full Name</h3>
                                    <div className="tip">Date Registered: {userData.created_at}</div>
                                </div>
                                <div className="text">
                                    <span className="ng-star-inserted">{userData.name}</span>
                                </div>
                            </div>
                            <div className="input-group personal-phone-group">
                                <label htmlFor="other">Phone Number
                                    {getotherContracts.length < 2 && (
                                        <div onClick={() => OpenPOP('phone')} className="add-btn">
                                            <div className="add-btn-icon" />
                                            <span>Add</span>
                                        </div>
                                        )}
                                </label>
                                <div className="input-wrap ng-star-inserted">
                                    <input type="text" disabled className="personal-number ng-tns-c1865069640-4 default" defaultValue={userData.phone} />
                                    <div
                                className={`status ${userData.session_id === '1' ? 'Verified' : 'unconfirm-btn'}`}
                                onClick={() => userData.session_id !== '1' && OpenPOP('unverifiedphone')}
                            >
                                {userData.session_id === '1' ? 'Verified' : 'Not Verified'}
                            </div>
                                </div>
                                {getotherContracts.length > 0 &&
                                        getotherContracts.map((contract, index) => (
                                <div  class="input-wrap ">
                                    <input  type="text" disabled class="personal-number" value={contract.contract_value || ""}/>
                                    <div   className={`status ${contract.isverify === 1 ? 'Verified' : 'unconfirm-btn'}`}> {contract.isverify === 1 ? 'Verified' : 'Not Verified'} </div>
                                </div>
                                ))}
                            </div>
                            <div className="input-group">
                                <label htmlFor="other">Email</label>
                                <div className="input-wrap ng-star-inserted">
                                    <input type="text" id="email" className="email profile ng-untouched ng-pristine" defaultValue={userData.email} disabled />
                                    <div
                                        className="status "
                                        onClick={() => OpenPOP('email')}
                                    >
                                        Verified
                                    </div>
                                </div>
                            </div>
                            <div className="input-group">
                                <label htmlFor="other">Birthday</label>
                                <div className="input-wrap">
                                    <input
                                        type="date"
                                        placeholder="YYYY/MM/DD"
                                        disabled={!isEditingBirthday}
                                        className="ng-star-inserted"
                                        value={dofb}
                                        onChange={(e) => setDofb(e.target.value)}
                                    />
                                    <div
                                        onClick={() => {
                                            if (isEditingBirthday) {
                                                updateDofb(); 
                                            }
                                            setIsEditingBirthday(!isEditingBirthday);
                                        }}
                                        className="status save-btn"
                                        style={{ display: 'block' }}
                                    >
                                        {isEditingBirthday ? 'Save' : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`pop-get-vcode ${verifyType === 'unverifiedphone' ? 'active' : ''}`}>
                            <a onClick={ClosePOP} className="btn-closed " style={{maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-cross-type01.svg?v=1741697022595")'}} />
                            <form noValidate className="ng-untouched ng-pristine ng-invalid">
                                <div className="menu-box">
                                    <div className="input-group">
                                        <label htmlFor="other"> Phone Number </label>
                                        <div className="input-wrap">
                                            <div className="input-wrap phone-wrap">
                                                <div className="phone-area-code">
                                                    <div className="lang-select ">
                                                        <button className="btn-select only">
                                                            <li className="">
                                                                <span className="">+88</span>
                                                            </li>
                                                        </button>
                                                    </div>
                                                </div>
                                                <input defaultValue={userData.phone} type="number"
                                              
                                                 name="phone" className="ng-untouched ng-pristine ng-invalid" />
                                            </div>
                                        </div>
                                        <label htmlFor="other">Input verification code</label>
                                        <div className="input-wrap">
                                        <input
                                            type="text"
                                            id="code"
                                            inputMode="numeric"
                                            className="ng-untouched ng-pristine ng-invalid"
                                            value={verificationCode}
                                            onChange={(e) => setVerificationCode(e.target.value)}
                                        />

                                            <a className="status get-vcode-btn">
                                                {isCodeSent ? (
                                                    <span className="send-code" style={{ display: 'inline' }}>
                                                        {Math.floor(timer / 60)}:{timer % 60 < 10 ? `0${timer % 60}` : timer % 60}
                                                    </span>
                                                ) : (
                                                    <span
                                                        onTouchStart={verfiyPhonCode}
                                                        onClick={verfiyPhonCode}
                                                        className="send-code"
                                                        style={{
                                                            display: 'inline',
                                                            pointerEvents: isButtonDisabled ? 'none' : 'auto', 
                                                            opacity: isButtonDisabled ? 0.5 : 1,
                                                        }}
                                                    >
                                                        Verify
                                                    </span>
                                                )}
                                            </a>
                                        </div>
                                        <div className="member-content">
                                            <div onClick={() => {
                                                verfiyPhoneNumber();
                                                ClosePOP();
                                            }}  className="button">
                                                Submit
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className={`pop-get-vcode ${verifyType === 'phone' ? 'active' : ''}`}>
                            <a onClick={ClosePOP} className="btn-closed " style={{maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-cross-type01.svg?v=1741697022595")'}} />
                            <form noValidate className="ng-untouched ng-pristine ng-invalid">
                                <div className="menu-box">
                                    <div className="input-group">
                                        <label htmlFor="other"> New Phone Number </label>
                                        <div className="input-wrap">
                                            <div className="input-wrap phone-wrap">
                                                <div className="phone-area-code">
                                                    <div className="lang-select ">
                                                        <button className="btn-select only">
                                                            <li className="">
                                                                <span className="">+88</span>
                                                            </li>
                                                        </button>
                                                    </div>
                                                </div>
                                                <input  type="number" value={phoneNumber} onChange={(e) => setphoneNumber(e.target.value)}
                                              
                                                 name="newphone" className="ng-untouched ng-pristine ng-invalid" />
                                            </div>
                                        </div>
                                        <label htmlFor="other">Input verification code</label>
                                        <div className="input-wrap">
                                        <input
                                            type="text"
                                            id="code"
                                            inputMode="numeric"
                                            className="ng-untouched ng-pristine ng-invalid"
                                            value={verificationCode}
                                            onChange={(e) => setVerificationCode(e.target.value)}
                                        />

                                            <a className="status get-vcode-btn">
                                                {isCodeSent ? (
                                                    <span className="send-code" style={{ display: 'inline' }}>
                                                        {Math.floor(timer / 60)}:{timer % 60 < 10 ? `0${timer % 60}` : timer % 60}
                                                    </span>
                                                ) : (
                                                    <span
                                                        onTouchStart={addPhoneNumber}
                                                        onClick={addPhoneNumber}
                                                        className="send-code"
                                                        style={{
                                                            display: 'inline',
                                                            pointerEvents: isButtonDisabled ? 'none' : 'auto', 
                                                            opacity: isButtonDisabled ? 0.5 : 1,
                                                        }}
                                                    >
                                                        Verify
                                                    </span>
                                                )}
                                            </a>
                                        </div>
                                        <div className="member-content">
                                            <div onClick={() => {
                                                addverfiyPhoneNumber();
                                                ClosePOP();
                                            }}  className="button">
                                                Add
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div onClick={ClosePOP} className={`pop-bg ${verifyType === 'unverifiedphone' ? 'd-block' : 'd-none'}`} />
                        <div className="member-menu-point ng-star-inserted">
                            <i>
                                <span className="item-icon" style={{ backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/theme-icon/icon-customer.png?v=1729676340443")' }} />
                            </i>
                            <p>For privacy and security, Information can’t be modified after confirmation. Please
                                <span name="liveChatBtn">contact customer service</span>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Toolbar />
        </div>
    );
}

export default Profile;
