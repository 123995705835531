import React, { useEffect } from "react";

function EXHome() {
  const exchangeToken = localStorage.getItem('exchangeToken');
  useEffect(() => {
    window.location.replace(`https://tech.betskyexch.com/m/#/token/${exchangeToken}`);
  }, []);

  return null;
}

export default EXHome;
