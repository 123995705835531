import React, { useState, useEffect } from 'react';
import MobileHeader from "../../layout/mobile/MobileHeader";
import MobileCarouselBanner from "../../sliders/Mobile";
import Announcement from "../../sliders/Mobile/Announcement";
import GameNavIcon from "../../navigation/mobile/GameNavIcon";
import Footer from "../../layout/mobile/Footer";
import ScrollBanner from "../../common/mobile/ScrollBanner";
import FeatureGames from "../../sliders/Mobile/FeatureGames";
import GuestToolbar from "../../layout/mobile/GuestToolbar";
import Toolbar from "../../navigation/mobile/Toolbar";
import Splash from '../../../splash/Splash';
import NewsPopUp from './common/NewsPopUp';
import RegisterPopUp from './common/RegisterPopUp';

function Home({globalData}) {
    const [isLoggedIn] = useState(!!localStorage.getItem('token'));
   
    const [showPopup, setShowPopup] = useState(false);
    const [regshowPopup, setSreghowPopup] = useState(false);

    useEffect(() => {
        
        if (localStorage.getItem("popup") === "1") {
            setShowPopup(true);
        }
        if (localStorage.getItem("regPopup") === "1") {
            setSreghowPopup(true);
        }
        
    }, []);

   

    return (
        <div className="main-router-wrapper">
            <MobileHeader />
            <div className="wrap">
                <div className="content">
                    <MobileCarouselBanner slidersMain={globalData.sliders?.main || []} />
                    <Announcement />
                    <GameNavIcon />
                    <ScrollBanner slidersFavourite={globalData.sliders?.favourite || []}  />
                    <FeatureGames  slidersFeatures={globalData.sliders?.feature || []}  />
                    {showPopup && <NewsPopUp />}
                    {showPopup && <RegisterPopUp />}
                </div>
            </div>
            <Footer />
            {isLoggedIn ? <Toolbar /> : <GuestToolbar />}
        </div>
    );
}

export default Home;
