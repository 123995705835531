import React, { useState, useEffect } from "react";

function RegisterPopUp(){
    const [showPopup, setShowPopup] = useState(true);
    
      useEffect(() => {
        const popupStatus = localStorage.getItem("regPopup");
        if (popupStatus === "0") {
          setShowPopup(false);
        }
      }, []);
    
      const handleClose = () => {
        localStorage.setItem('regPopup', '0');
        setShowPopup(false);
      };
    
      if (!showPopup) return null;

    return(
    <div className="cdk-overlay-container">
  <div className="cdk-overlay-backdrop dialog-backdrop cdk-overlay-backdrop-showing" />
  <div className="cdk-global-overlay-wrapper" dir="ltr" style={{justifyContent: 'center', alignItems: 'center'}}>
    <div id="cdk-overlay-0" className="cdk-overlay-pane dialog-panel" style={{position: 'static'}}>
     
        <div  className="popup" id="dialog-0">
          <div  className="popup__header" />
          <div  className="popup__content">
            
              <div className="pop-wrap register-success-wrap ng-trigger ng-trigger-popWrapTriggerAni show ani ng-star-inserted" style={{display: 'block'}}>
                <div className="register-success-cont">
                  <div className="register-success-txt top-inner">
                    <div className="success-checkmark">
                      <div className="check-icon">
                        <span className="icon-line line-tip" />
                        <span className="icon-line line-long" />
                        <div className="icon-circle" />
                        <div className="icon-fix" />
                      </div>
                    </div>
                    <div className="ng-tns-c4168059928-6">
                      <h3>Welcome</h3>
                      <h4>You are now a member of our Platfrom</h4>
                    </div>
                    <p className="ng-tns-c4168059928-6">
                      Step into the world full of adventure with us! Start placing your bets and good luck!<br />
                      আমাদের সাথে অ্যাডভেঞ্চারের পূর্ণ বিশ্বে বিচরণ করুন! আপনার বেট রাখা শুরু করুন এবং আপনার জন্য শুভকামনা!
                    </p>
                  </div>
                  <div className="register-success-btn">
                    <a onClick={handleClose} href="/" className="button btn-default btn-secondary">Home Page</a>
                    <a onClick={handleClose} href="/deposit" className="button btn-default">Deposit now</a>
                  </div>
                </div>
              </div>
              <div onClick={handleClose} className="pop-bg" style={{display: 'block'}} />
           
          </div>
        </div>
    </div>
  </div>
</div>

    );
}
export default RegisterPopUp;