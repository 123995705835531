import React, { useState, useEffect, useRef } from "react";

// LazyLoadImage component for background images
const LazyLoadImage = ({ src, style }) => {
  const [isVisible, setIsVisible] = useState(false);
  const imgRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );
    
    if (imgRef.current) observer.observe(imgRef.current);
    
    return () => {
      if (imgRef.current) observer.disconnect();
    };
  }, []);

  return (
    <div
      ref={imgRef}
      className="item-pic"
      style={{
        backgroundImage: isVisible ? `url(${src})` : 'none',
        ...style,
      }}
    />
  );
};

const MobileCarouselBanner = ({slidersMain}) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [progress, setProgress] = useState(0);
    useEffect(() => {
        if (slidersMain.length > 0) {
            const progressInterval = setInterval(() => {
                setProgress((prev) => (prev < 100 ? prev + 1 : 0));
            }, 30);

            const slideInterval = setInterval(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % slidersMain.length);
                setProgress(0); 
            }, 3000);

            return () => {
                clearInterval(progressInterval);
                clearInterval(slideInterval);
            };
        }
    }, [slidersMain.length]);

    const goToSlide = (index) => {
        setCurrentIndex(index);
        setProgress(0);
    };

    return (
        <div dir="ltr" className="banner">
            <div className="banner-v1">
                <div className="carousel-wrap style-init siblings" data-auto="true">
                    <div className="cdk-drag item-drag" style={{ transform: `translate3d(0px, 0px, 0px)` }}>
                        <div className="item-left">
                            <div
                                className="item-wrap"
                                style={{
                                    transform: `translate3d(${(currentIndex % slidersMain.length) * -100}%, 0px, 0px)`,
                                    transition: 'transform 0.5s ease-in-out',
                                }}
                            >
                                {slidersMain.map((slider, index) => (
                                    <div
                                        key={slider.message_id}
                                        className="item"
                                        message-id={slider.message_id}
                                        style={{ width: '100%' }}
                                        onClick={() => goToSlide(index)}
                                    >
                                        <LazyLoadImage
                                            src={`${process.env.REACT_APP_API_URL}/storage/${slider.thubmnail}`}
                                            style={{ backgroundSize: 'cover', backgroundPosition: 'center' }}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <ul className="dot-group style-bar">
                        {slidersMain.map((_, index) => (
                            <li
                                key={index}
                                onClick={() => goToSlide(index)}
                                className={`ng-star-inserted ${index === currentIndex ? 'active' : ''}`}
                            >
                                <span className="dot-progress" style={{ animationDuration: '3000ms' }} />
                            </li>
                        ))}
                    </ul>
                    <input value={progress} type="hidden" />
                </div>
            </div>
        </div>
    );
};

export default MobileCarouselBanner;
