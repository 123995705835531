import React, { useState, useEffect } from "react";
import { useLocation, useNavigate  } from "react-router-dom";
import { Modal } from "react-bootstrap"; 

function MakePayment(){

    const [accountNumber, setAccountNumber] = useState("");
    const [transactionId, setTransactionId] = useState("");
    const [submitLoading, setSubmitLoading] = useState(false);
     const [isLoading, setIsLoading] = useState(false); 
    const [showModal, setShowModal] = useState(false);
    const [arrivalTime, setArrivalTime] = useState(30); 
    const [modalMessage, setModalMessage] = useState("");
    const token = localStorage.getItem('token');
    const navigate = useNavigate(); 
    const location = useLocation(); 
    const { amount, payment_icon, payment_type, paymentMethodid, paymentMethodName, promotion } = location.state || {}; 
    const username = localStorage.getItem('username');
    const [paymentSystems, setPaymentSystem] = useState([]);

   
    
    useEffect(() => {
        if (paymentMethodName) {
          const fetchPaymentMethodDetails = async () => {
            try {
              const response = await fetch(`${process.env.REACT_APP_API_URL}/api/get-wallet-number?name=${paymentMethodName}&account_type=${payment_type}&amount=${amount}`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Origin': window.location.origin,
                },
              });
              const data = await response.json();
    
              if (data) {
                setPaymentSystem(data[0]);
                console.log(data);
              }
            } catch (error) {
              console.error("Error fetching payment method details:", error);
            } finally {
              setIsLoading(false);
            }
          };
    
          fetchPaymentMethodDetails();
        }
      }, [paymentMethodName, token]);
     useEffect(() => {
           
           const countdown = setInterval(() => {
               setArrivalTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
           }, 60000); 
   
           return () => clearInterval(countdown);
       }, []);

    const handleSubmit = async () => {
        // Validate each field with specific error messages
        if (!accountNumber || accountNumber.length !== 11 || !/^\d{11}$/.test(accountNumber)) {
          setModalMessage("Please provide a valid 11-digit account number.");
          setShowModal(true);
          return;
        }
        
        if (!transactionId) {
          setModalMessage("Please provide your TRX ID. It should be valid.");
          setShowModal(true);
          return;
        }
      
        setSubmitLoading(true);
        const remoteId = localStorage.getItem("remote_id");
      
        const requestBody = {
          remote_id: remoteId,
          account_number: accountNumber,
          transaction_id: transactionId,
          amount: amount,
          payment_id: paymentSystems.id,
          promotion_id: promotion, 
        };
      
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/submit-deposit`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(requestBody),
          });
      
          if (!response.ok) {
            const errorData = await response.json();
            setModalMessage("Submission failed: " + errorData.message);
            setShowModal(true);
            return;
          }
      
          const data = await response.json();
      
          if (data) {
            setModalMessage("Submission successful!");
      
            navigate("/payment-request", {
              state: {
                amount: amount,
                payment_icon: payment_icon,
              },
            });
          } else {
            setModalMessage(data.message);
            setShowModal(true); // Show modal with the error message
          }
        } catch (error) {
          setModalMessage("An error occurred. Please try again.");
          setShowModal(true); // Show modal on error
        } finally {
          setSubmitLoading(false);
        }
      };
      
    return(
      <div className="third-party-box third-party-mort-cont">
      <div className="third-party-title">
        <img src={payment_icon? `${process.env.REACT_APP_API_URL}/storage/${payment_icon}` : `${process.env.REACT_APP_API_URL}/default-icon.png`} />
        <h3>{paymentMethodName} Payment</h3>
      </div>
      <div className="third-party-bar">
        {/* <p className="number">Transaction ID: <span>D766499351</span></p>
        <p className="times">Transaction Create Time: <span id="timestamp">2025/04/01 16:50:47 (GMT+6)</span></p> */}
      </div>
      <div className="third-party-main">
        <div className="tips-group">
          <ul>
            <li>Please ensure amount to deposit is the same as transferred amount. We will not be liable for missing funds due to incorrect information.</li>
            <li>Please use phone number registered on our site for cash out, deposits with 3rd party phone numbers are restricted.</li>
          </ul>
        </div>
        <h2>
          Please cash out to the account below within <span id="min">{arrivalTime}</span><br />
          minutes after submitting the deposit form
        </h2>
        <div className="third-party-content third-party-account-content">
        <img src={`./icons/payment-bg.png`} />
          <div className="pay-wrap">
            <div className="pay-inner">
              <div className="pay-block">
                <div className="text">
                  <div className="pay-account">
                    <h4>{paymentSystems.account_number}</h4>
                    <a className="button-icon-copy" onClick={() => {
                const accountNumber = paymentSystems.account_number;
                if (accountNumber) {
                  navigator.clipboard.writeText(accountNumber);
                  alert(`Account number copied: ${accountNumber}`);
                } else {
                  alert("No account number to copy!");
                }
              }}>Copy </a>
                  </div>
                  <div className="pay-info">
                    <p>
                      Amount:
                      <span>{amount}.00</span>
                    </p>
                    <a className="button-icon-copy" onClick={() => {
                    if (amount) {
                      navigator.clipboard.writeText(amount.toString());
                      alert(`Amount copied: ${amount}`);
                    } else {
                      alert("No Amount to copy!");
                    }
                  }}>Copy </a>
                  </div>
                  <div className="qrcode" style={{display: 'none'}} id="qrCodeContainer" />
                </div>
                <div className="receipt-wrap">
                  <form id="updateForm" name="updateForm" action noValidate="novalidate">
                    <div className="receipt-inner">
                      <div className="bank-acc-wrap">
                        <h3>Phone Number / Cash Out No.</h3>
                        <input type="text"  
                        value={accountNumber}
                        onChange={(e) =>
                          setAccountNumber(e.target.value)
                        } name="accountNumber"
                         placeholder="Please input data" 
                        aria-required="true" style={{textTransform: 'uppercase'}} />
                        
                      </div>
                      <div className="reference-wrap">
                        <h3>Reference No. / Trans ID</h3>
                        <input type="text"  
                        value={transactionId} 
                        onChange={(e) =>setTransactionId(e.target.value)}
                        name="transactionId" placeholder="Please input data" 
                        aria-required="true" style={{textTransform: 'uppercase'}} />
                      </div>
                    </div>
                    <div className="upload-wrap">
                      <div className="title">
                        <h3>Upload receipt</h3>
                        <button id="clearUploadImages">Reset Receipt</button>
                      </div>
                      <input id="uploadImages" name="uploadImages" type="file" accept="image/*" multiple />
                      <div className="dropImages" id="dropImages">Drop file here</div>
                      <img id="previewImage" style={{marginTop: '10px', maxWidth: '100%', maxHeight: '310px'}} draggable="false" src alt="" />
                    </div>
                  </form>
                </div>
                <button onClick={handleSubmit} id="confirm" className="submit-btn">Submit</button>
              </div>
              <div id="resultBlock" className="pay-panding">
                <div className="text">
                  <h4>{paymentSystems.account_number}</h4>
                  <p className="pay-info">Amount: <span>{amount}.00</span></p>
                </div>
                <div className="status-wrap">
                  <p>
                    Thank You!<br />
                    Our are verifying your transaction, Please hold on.
                  </p>
                  <br />
                  <div className="status"><span>Pending...</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>Error</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>{modalMessage}</p>
                            </Modal.Body>
                        </Modal>
    </div>
    
    );
}
export default MakePayment;