import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const SignUp = () => {
    const referralCode = localStorage.getItem('referral_code');
        const affiliateCode = localStorage.getItem('affiliate_code');
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        confirmPassword: '',
        name: '',
        phone: '',
        email: '',
        referral_code: '',
        currency: 'BDT',
        status: 'active',
    });
    const navigate = useNavigate();

    const [errors, setErrors] = useState({});
    const [passwordShown, setPasswordShown] = useState(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
    
    useEffect(() => {
        
        if (referralCode) {
            setFormData(prevData => ({ ...prevData, referral_code: referralCode }));
        }else{
            setFormData(prevData => ({ ...prevData, referral_code: affiliateCode }));
        }
    }, [referralCode, affiliateCode]);

    const togglePasswordVisibility = () => setPasswordShown(!passwordShown);
    const toggleConfirmPasswordVisibility = () => setConfirmPasswordShown(!confirmPasswordShown);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validate = () => {
        let tempErrors = {};
        const usernamePattern = /^[a-zA-Z0-9]+$/;
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phonePattern = /^[0-9]{10,15}$/;
        const passwordPattern = /^[a-zA-Z0-9]+$/; // Alphanumeric characters only (no special characters)

        // Username validation
        if (!formData.username) tempErrors.username = "Username is required";
        else if (!usernamePattern.test(formData.username)) tempErrors.username = "Invalid username (only letters and numbers)";

        // Email validation
        if (!formData.email) tempErrors.email = "Email is required";
        else if (!emailPattern.test(formData.email)) tempErrors.email = "Invalid email address";

        // Phone number validation
        if (!formData.phone) tempErrors.phone = "Phone number is required";
        else if (!phonePattern.test(formData.phone)) tempErrors.phone = "Invalid phone number (10-15 digits)";

        // Password validation
        if (!formData.password) tempErrors.password = "Password is required";
        else if (formData.password.length < 8) tempErrors.password = "Password must be at least 8 characters";
        else if (!passwordPattern.test(formData.password)) tempErrors.password = "Password can only contain letters and numbers (no special characters)";

        // Confirm password validation
        if (!formData.confirmPassword) tempErrors.confirmPassword = "Confirm Password is required";
        else if (formData.confirmPassword !== formData.password) tempErrors.confirmPassword = "Passwords do not match";


        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Log formData before submitting
        console.log("Submitting formData:", formData);
    
        if (validate()) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/create-players`, {
                    method: 'POST',
                    headers: {
                        
                        'Content-Type': 'application/json',
                        'Origin': window.location.origin,
                    },
                    body: JSON.stringify(formData),
                    
                });
    
                // Parse response
                const data = await response.json();
    
                // Handle response status
                if (response.ok) {
                    // Auto login after signup
                    const loginResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/login`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            username: formData.username,
                            password: formData.password,
                        }),
                    });
                
                    const loginData = await loginResponse.json();
                
                    if (loginResponse.ok) {
                        // Save login data
                        localStorage.setItem('token', loginData.token);
                        localStorage.setItem('remote_id', loginData.remote_id);
                        localStorage.setItem('username', loginData.username);
                        localStorage.setItem('exchangeToken', loginData.exchange[0].token);
                        localStorage.setItem('popup', 1);
                        localStorage.setItem('regPopup', 1);
                
                        // Redirect to home
                        window.location.href = '/';
                    } else {
                        console.error("Auto login failed:", loginData.message);
                    }
                } else {
                    console.error("Server validation errors:", data);
                    // Update errors with server response
                    setErrors(data.errors || { general: "Submission failed" });
                }
            } catch (error) {
                console.error("Error during signup:", error);
                setErrors({ general: "Network error, please try again" });
            }
        } else {
            console.log("Form has errors. Please fix them and try again.");
            console.error(errors)
        }
    };
    

    return (
        <div className="main-router-wrapper ng-trigger ng-trigger-routeFullPageAni">
            <header className="ng-star-inserted member">
                <div className="header-left-btn-group">
                    <Link to={'/'}>
                        <div
                            className="back-btn ng-star-inserted"
                            style={{
                                maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-arrow-type09.svg?v=1729676340443")',
                            }}
                        />
                    </Link>
                </div>
                <div className="header-title ng-star-inserted">Sign up</div>
            </header>
            <div className="wrap">
                <div className="content member-content normal-register">
                    <div className="ng-trigger ng-trigger-routeLayoutPageAni">
                        <div className="ng-star-inserted">
                            <div className="banner">
                                <div className="carousel-wrap siblings ng-star-inserted">
                                    <div className="item-drag">
                                        <div className="item-left">
                                            <div className="item ng-star-inserted" style={{ width: '301px', position: 'absolute', left: '50%', transform: 'translate(-50%)' }}>
                                                <div className="item-pic ng-star-inserted" style={{ backgroundImage: 'url("https://img.j189eb.com/upload/registerH5Slider/image_162387.jpg")' }} />
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="dot-group style-bar">
                                        <li href="#" value={0} className="active ng-star-inserted">
                                            <span className="dot-progress" style={{ animationDuration: '3000ms' }} />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="inner-wrap">
                                <div className="inner-box">
                                    <form onSubmit={handleSubmit} noValidate className="ng-untouched ng-pristine ng-invalid">
                                        <div className="member-box radius">
                                            <div className="inputbox-wrapper">
                                                <div className="inputbox error">
                                                    <label>Username</label>
                                                    <input
                                                        name="username"
                                                        type="text"
                                                        className="input ng-untouched ng-pristine ng-invalid"
                                                        placeholder="4-15 char, allow number"
                                                        value={formData.username}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                
                                                {errors.username && <div  className="member-error"><span> {errors.username} </span></div>}
                                            </div>
                                            <div className="inputbox-wrapper">
                                                <div className="inputbox password">
                                                <div
                                                        className={`eyes ${passwordShown ? 'active' : ''}`}
                                                        onClick={togglePasswordVisibility}
                                                    />
                                                    <label>Password</label>
                                                    <input
                                                        name="password"
                                                        type={passwordShown ? "text" : "password"}
                                                        className="input ng-untouched ng-pristine ng-invalid"
                                                        placeholder="6-20 Characters and Numbers"
                                                        value={formData.password}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                
                                                {errors.password && <div  className="member-error"><span> {errors.password} </span></div>}
                                            </div>
                                            <div className="inputbox-wrapper">
                                                <div className="inputbox password">
                                                <div
                                                        className={`eyes ${confirmPasswordShown ? 'active' : ''}`}
                                                        onClick={toggleConfirmPasswordVisibility}
                                                    />
                                                    <label>Confirm Password</label>
                                                    <input
                                                        name="confirmPassword"
                                                        type={confirmPasswordShown ? "text" : "password"}
                                                        className="input ng-untouched ng-pristine ng-invalid"
                                                        placeholder="confirm password"
                                                        value={formData.confirmPassword}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                {errors.confirmPassword && <div  className="member-error"><span> {errors.confirmPassword} </span></div>}
                                                
                                            </div>
                                            <div className="inputbox-wrapper">
                                                <div className="selectbox ng-star-inserted">
                                                    <label>Currency</label>
                                                    <select
                                                        name="currency"
                                                        formcontrolname="currencyType"
                                                        className="ng-untouched ng-pristine ng-valid"
                                                        value={formData.currency}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="BDT">BDT</option>
                                                        <option value="INR">INR</option>
                                                        <option value="PKR">PKR</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="member-box radius">
                                            <div className="inputbox-wrapper">
                                                <div className="inputbox ng-star-inserted">
                                                    <label>Full Name</label>
                                                    <input
                                                        name="name"
                                                        type="text"
                                                        className="input ng-untouched ng-pristine ng-invalid"
                                                        placeholder="Full Name"
                                                        value={formData.name}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="inputbox-wrapper">
                                                <div className="inputbox phone">
                                                    <label>Phone Number</label>
                                                    <div className="phone-area-code">
                                                        <div className="lang-select">
                                                            <button className="btn-select only">
                                                                <li><span>+88</span></li>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <input
                                                        name="phone"
                                                        type="text"
                                                        className="input ng-untouched ng-pristine ng-invalid"
                                                        placeholder="Phone Number"
                                                        value={formData.phone}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                
                                                {errors.phone && <div  className="member-error"><span> {errors.phone} </span></div>}

                                            </div>
                                            <div className={`inputbox-wrapper`}>
                                                <div className={`inputbox`}>
                                                    <label>E-mail</label>
                                                    <input
                                                        name="email"
                                                        type="text"
                                                        className="input ng-untouched ng-pristine ng-invalid"
                                                        placeholder="E-mail"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                
                                                {errors.email && <div  className="member-error"><span> {errors.email} </span></div>}

                                            </div>
                                            <div className="inputbox-wrapper">
                                                <div className="inputbox ng-star-inserted">
                                                    <label>Referral Code</label>
                                                    <input
                                                        name="referral_code"
                                                        type="text"
                                                        className="input ng-untouched ng-pristine ng-valid"
                                                        placeholder="Enter if you have one"
                                                        value={formData.referral_code}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <button className="button">
                                            SUBMIT
                                        </button>
                                        <p>I'm 18 years old, and agree to "terms and conditions".</p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUp;
