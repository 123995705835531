import React, { useCallback, useState, useEffect } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./components/assets/css/LiveChat.css";
import DHome from './components/pages/desktop/Home';
import DCasino from './components/pages/desktop/Casino';
import Promotion from './components/pages/desktop/Promotion';
import Account from './components/pages/desktop/Account';
import Registion from './components/pages/desktop/Registion';
import Home from './components/pages/mobile/Home';
import Login from './components/pages/mobile/Login';
import SignUp from './components/pages/mobile/SignUp';
import MAccount from './components/pages/mobile/MAccount';
import Profile from './components/pages/mobile/Profile';
import MDeposit from './components/pages/mobile/MDeposit';
import MWithdrawal from './components/pages/mobile/MWithdrawal';
import BettingRecord from './components/pages/mobile/BettingRecord';
import TransactionRecord from './components/pages/mobile/TransactionRecord';
import TurnoverRecord from './components/pages/mobile/TurnoverRecord';
import RefrrelProgram from './components/pages/mobile/RefrrelProgram';
import ResetPassword from './components/pages/mobile/ResetPassword';
import MobileInbox from './components/pages/mobile/MobileInbox';
import VIPConvert from './components/pages/mobile/VIPConvert';
import Games from './components/pages/mobile/Games';
import GameRun from './components/pages/mobile/GameRun';
import MobilePromotion from './components/pages/mobile/MobilePromotion';
import ChatHome from './components/Chat/ChatHome';
import ChatHistory from './components/Chat/ChatHistory';
import ChatBox from './components/Chat/ChatBox';
import EXHome from './components/exchanges/EXHome';
import MatchOdds from './components/exchanges/MatchOdds';
import ExAccount from './components/exchanges/ExAccount';
import InPlay from './components/exchanges/InPlay';
import PaymentTimer from './components/pages/mobile/common/PaymentTimer';
import ForgotPassword from './components/pages/mobile/ForgotPassword';
import StoreCode from './StoreCodeComponents';
import MakePayment from './components/pages/mobile/common/MakePayment';
import Splash from './splash/Splash';
import APIS from './components/pages/mobile/common/apis';
import GoToTop from './components/common/GoTop';

// Utility function to check if the device is mobile
const isMobile = () => /Mobi|Android/i.test(navigator.userAgent);

function App() {
  const isDeviceMobile = isMobile();
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
  const [showSplash, setShowSplash] = useState(true);
  const [logo, setLogo] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false); 
  const location = useLocation();
  const [globalData, setGlobalData] = useState({});

  // Function to dynamically set the favicon
  const setFavicon = (url) => {
    const link = document.querySelector("link[rel~='icon']");
    if (!link) {
      const newLink = document.createElement("link");
      newLink.rel = "icon";
      newLink.href = url;
      document.head.appendChild(newLink);
    } else {
      link.href = url;
    }
  };
  const [cssLoading, setCssLoading] = useState(false);

  // Fetch promotions data and set logo & favicon
  useEffect(() => {
    const fetchPromotions = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/logo`);
        if (!response.ok) {
          throw new Error("Failed to fetch promotions");
        }
        const data = await response.json();
        setLogo(data[0].loader_logo);
        setFavicon(`${process.env.REACT_APP_API_URL}/storage/${data[0].favicon}`);
        console.log(data[0].loader_logo);
      } catch (error) {
        console.error("Error fetching promotions:", error);
      }
    };

    fetchPromotions();
  }, []);

  // Fetch general site data
  useEffect(() => {
    const fetchGeneral = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/general`);
        if (!response.ok) {
          throw new Error("Failed to fetch General");
        }
        const data = await response.json();
        if (data) {
          document.title = data[0].site_title;
          console.log(data[0].site_title);
        }
      } catch (error) {
        console.error("Error fetching General:", error);
      }
    };

    fetchGeneral();
  }, []);

  // Monitor when both API calls are finished
  useEffect(() => {
    if (logo && document.title) {
      setIsDataLoaded(true); 
    }
  }, [logo]);

 
  useEffect(() => {
    if (isDataLoaded) {
      const splashTimeout = setTimeout(() => setShowSplash(false)); 
      return () => clearTimeout(splashTimeout);
    }
  }, [isDataLoaded]);

  

  const fetchBalance = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/balance`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          localStorage.setItem('balance', data.balance);
        } else if (response.status === 401) {
          handleLogout();
        }
      } catch (error) {
        console.error('Error fetching balance:', error);
      }
    } else {
      console.warn('No token found in local storage.');
    }
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    setIsLoggedIn(false);
    window.location.href = '/';
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (isLoggedIn) {
      fetchBalance();
      const interval = setInterval(fetchBalance, 10000);
      return () => clearInterval(interval);
    }
  }, [isLoggedIn, fetchBalance]);


  const PrivateRoute = ({ element }) => {
    return isLoggedIn ? element : <Navigate to="/login" />;
  };

  useEffect(() => {
    let extraCssLink;

    if (location.pathname === "/make-payment") {
      setCssLoading(true); 

      extraCssLink = document.createElement("link");
      extraCssLink.rel = "stylesheet";
      extraCssLink.href = "./extra.css";
      extraCssLink.id = "extra-css";

      extraCssLink.onload = () => {
        setCssLoading(false); 
      };

      extraCssLink.onerror = () => {
        console.error("Failed to load extra.css");
        setCssLoading(false);
      };

      document.head.appendChild(extraCssLink);
    }

    // Dynamic import for other CSS
    const loadCSS = async () => {
      try {
        if (isDeviceMobile) {
          await import("./components/assets/css/Mobile.css");
          await import("./components/assets/css/flipclock.css");
        } else {
          await import("./components/assets/css/main-ui.css");
          await import("./components/assets/css/palyer-ui.css");
        }
      } catch (err) {
        console.error("Dynamic CSS import error:", err);
      }
    };

    loadCSS();

    return () => {
      const existingLink = document.getElementById("extra-css");
      if (existingLink) {
        document.head.removeChild(existingLink);
      }
    };
  }, [isDeviceMobile, location.pathname]);
  
  return (
    <div className={`App ${isDeviceMobile ? 'mobile' : 'desktop'}`}>
       <GoToTop/>
       <APIS setGlobalData={setGlobalData}/>
       {cssLoading && (
        <Splash/>
      )}
        <Routes>
          {isDeviceMobile ? (
            <>
              <Route path="/" element={<Home globalData={globalData} />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/login" element={<Login />} />
              <Route path="/games" element={<Games />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route
                path="/account"
                element={
                  <PrivateRoute
                    element={
                      <MAccount
                        userData={globalData.userData}
                        general={globalData.general || {}}          
                        sociallink={globalData.socialLinks || {}}  
                      />
                    }
                  />
                }
              />

              <Route path="/profile" element={<PrivateRoute element={<Profile />} />} />
              <Route path="/deposit" element={<PrivateRoute element={<MDeposit  items={globalData.paymentMethods || []}  promotions={globalData.promotions?.activePromotion || []} />} />} />
              <Route path="/withdrawal" element={<PrivateRoute element={<MWithdrawal items={globalData.paymentMethods || []} userData={globalData.userData}  />} />} />
              <Route path="/betting-record" element={<PrivateRoute element={<BettingRecord />} />} />
              <Route path="/transaction-record" element={<PrivateRoute element={<TransactionRecord transactionData={globalData.transactions || [] } />} />} />
              <Route path="/turnover-record" element={<PrivateRoute element={<TurnoverRecord  turnovers={globalData.turnovers || []} />} />} />
              <Route path="/refrrel-program" element={<PrivateRoute element={<RefrrelProgram  userData={globalData.userData} />} />} />
              <Route path="/reset-password" element={<PrivateRoute element={<ResetPassword />} />} />
              <Route path="/inbox" element={<PrivateRoute element={<MobileInbox />} />} />
              <Route path="/vip" element={<PrivateRoute element={<VIPConvert />} />} />
              <Route path="/gamerun" element={<PrivateRoute element={<GameRun />} />} />
              <Route path="/promotions" element={<PrivateRoute element={<MobilePromotion promotions={globalData.promotions?.displayPromotion || []} />} />} />
              <Route path="/chat" element={<PrivateRoute element={<ChatHome />} />} />
              <Route path="/chat-list" element={<PrivateRoute element={<ChatHistory />} />} />
              <Route path="/chat-box/:chatId" element={<PrivateRoute element={<ChatBox />} />} />
              <Route path="/odds-view" element={<PrivateRoute element={<MatchOdds />} />} />
              <Route path="/exchange" element={<PrivateRoute element={<EXHome />} />} />
              <Route path="/exchange-account" element={<PrivateRoute element={<ExAccount />} />} />
              <Route path="/exchange-inplay" element={<PrivateRoute element={<InPlay />} />} />
              <Route path="/payment-request" element={<PrivateRoute element={<PaymentTimer />} />} />
              <Route path="/referral_code" element={<StoreCode codeType="referral_code" />} />
              <Route path="/affiliate_code" element={<StoreCode codeType="affiliate_code" />} />
              <Route path="/make-payment" element={<MakePayment />} />
            </>
          ) : (
            <>
              <Route path="/" element={<DHome />} />
              <Route path="/account" element={<Account />} />
              <Route path="/casino" element={<DCasino />} />
            </>
          )}
          <Route path="/promotions" element={<Promotion />} />
          <Route path="/register" element={<Registion />} />
        </Routes>
      
    </div>
  );
}

export default App;
